<template>
  <div>
    <div class="introsection wf-section">
      <div class="othercontainer w-container">
        <div
          data-animation="default"
          data-collapse="all"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          class="navbar-2 w-nav"
        >
          <div class="othercontainer navcontainer w-container">
            <a href="/" class="w-nav-brand"
              ><img
                src="images/cloudious-new-logo.png"
                loading="lazy"
                width="125"
                alt=""
                class="image-15"
            /></a>
            <nav role="navigation" class="w-nav-menu">
              <div class="menutab">
                <a
                  href="https://platform.cloudious.net/#/login"
                  class="navbutton-copy w-button"
                  >Login</a
                >
              </div>
            </nav>
            <div class="menu-button-2 w-nav-button">
              <a
                href="https://platform.cloudious.net/#/login"
                class="navbutton w-button"
                >Login</a
              >
              <a
                href="mailto: support@cloudious.net"
                class="navbutton contactBtn w-button"
                style="margin-left: 10px"
                >contact</a
              >
              <img
                src="images/icons8-menu-64.png"
                loading="lazy"
                width="42"
                alt=""
                class="menuimg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="general-section privacy-section wf-section">
      <div class="othercontainer w-container">
        <h1 class="privacy-title">Refund Policy</h1>
        <div class="privacy-content">
          <div class="gb-col-md-10 gb-col-lg-8 gb-col-lg-10">
            <p>
              We have prepared this Refund Policy to explain when you can
              request a refund with respect to products and services acquired
              from Cloudious or through us from our partners and/or affiliates
              (collectively “Services”).
            </p>
            <h2>General Terms</h2>
            <p>
              General terms apply to all refund eligible Services. In addition,
              there may be special term(s) that apply to a specific product
              and/or service and those are outlined below.
            </p>
            <ul class="gb-list">
              <li>
                In no event is the same Service eligible for more than one
                refund.
              </li>
              <li>
                Service(s) must be cancelled before we can issue a refund.
              </li>
              <li>
                No refund will be made for domains involved in Trademark
                Infringement. It includes domains that are the subject of and/or
                involved in a Trademark Infringement allegation and/or
                proceeding.
              </li>
              <li>
                No refund will be made if your Service(s) are suspended or
                terminated for cause.
              </li>
              <li>
                If purchased Service(s) include free domain name registration as
                part of a promotion or sale of the Service(s), and you cancel
                the purchased Service(s), the standard price for the domain name
                will be deducted from the refund amount. If the refund amount is
                less than the standard price for the domain, you must either
                elect to pay the difference or forfeit the domain, in which
                case, ownership of the domain will revert back to Cloudious.
              </li>
              <li>
                All refunds are processed in U.S. dollars: you, the customer,
                bear sole responsibility for any fluctuations in the exchange
                rate(s) between the time of payment and the time of refund.
              </li>
              <li>
                Unless you request otherwise, refunds will be credited to your
                Cloudious account balance. Purchases made using Cloudious
                account funds can only be refunded as account credit. Purchases
                made by credit card and PayPal may be refundable to the original
                source of payment. Refunds are not possible for cryptocurrency
                deposits. Cloudious is not responsible for any additional
                charges imposed by your credit card company or PayPal payment
                processor in the case of refund.
              </li>
              <li>Any processing fee(s) will be deducted from a refund.</li>
            </ul>
            <p>
              Additional refund requirements may apply based on specific terms
              and conditions notified to you when purchasing any Service(s).
            </p>
            <h2 class="wp-block-heading" id="special-terms">Special Terms</h2>
            <p><strong>Domain Name Registration</strong></p>
            <ul class="gb-list">
              <li>
                Unless this Refund Policy specifically provides for a refund,
                all fees for domain name registrations and related domain name
                Service(s) are non-refundable, in whole or in part, even if your
                domain name registration is suspended, cancelled or transferred
                prior to the end of your then current registration term.
              </li>
              <li>
                New domain name registrations may be refundable, at the sole
                discretion of Cloudious, if you, the registrant, cancel the
                domain name registration for a valid reason and the cancellation
                is processed within 5 days (120 hours) after registration.
                Please note that, in some cases, cancellation and refund are not
                available for new domain registrations due to restrictions
                imposed by the applicable registry or registry operating
                company.
              </li>
              <li>
                No refunds are available for fees paid for After Market Domain
                Names unless your After Market Domain Name registration is
                rejected or cancelled by Cloudious, the registry or the third
                party seller.
              </li>
              <li>
                No refunds are available for the Cloudious Aftermarket
                subscription.
              </li>
              <li>
                No refunds are available for fees paid for Premium Domain(s).
              </li>
              <li>
                No refunds are available for fees paid for any of the following:
              </li>
              <ul class="gb-list">
                <li>
                  Additional fees paid for Pre-Orders, Sunrise or Landrush
                  periods or participation in an Early Access Program; or
                </li>
                <li>Fees paid for related Service(s).</li>
              </ul>
              <li>
                In the case of an unsuccessful attempt to transfer a domain name
                to Cloudious, we will automatically refund any fees paid as an
                account credit.
              </li>
            </ul>
            <p><strong>Domain Name Renewal</strong></p>
            <ul class="gb-list">
              <li>
                Unless this Refund Policy specifically provides for a refund,
                all fees for domain name renewals and related domain name
                Service(s) are non-refundable, in whole or in part, even if your
                domain name is suspended, cancelled or transferred prior to the
                end of your then current registration term.
              </li>
              <li>
                A domain name renewal may be refundable, at the sole discretion
                of Cloudious, if you, the registrant, cancel the domain name
                renewal for a valid reason and the cancellation is processed
                within 5 days (120 hours) after renewal for the most of TLDs.
                Please note that, in some cases, cancellation and refund are not
                available for domain renewals due to restrictions imposed by the
                applicable registry or registry operating company.
              </li>
              <li>It is not possible to cancel renewals for:</li>
              <ul class="gb-list">
                <li>**.AU,</li>
                <li>.CA,</li>
                <li>.CH,</li>
                <li>.CM,</li>
                <li>.CX,</li>
                <li>.DE,</li>
                <li>**.ES,</li>
                <li>.EU,</li>
                <li>.GG,</li>
                <li>.FR,</li>
                <li>.IS,</li>
                <li>.LI,</li>
                <li>.NL,</li>
                <li>.NU,</li>
                <li>**.PE,</li>
                <li>**.PH,</li>
                <li>**.SG,</li>
                <li>.TO,</li>
                <li>**.UK,</li>
                <li>.WS.</li>
              </ul>
            </ul>
            <p><strong>Web Hosting</strong></p>
            <ul class="gb-list">
              <li>
                Unless this Refund Policy specifically provides for a refund,
                all fees for Web Hosting and related Service(s) are
                non-refundable, in whole or in part, even if your Web Hosting
                Service(s) are suspended or terminated prior to the end of your
                then current contract term.
              </li>
              <li>
                Package fees for Shared Hosting, Stellar, Stellar Plus, Stellar
                Business, Reseller Hosting, Nebula, Galaxy Expert, Universe Pro,
                VPS hosting, VPS Pulsar, VPS Quasar, and VPS Magnetar may be
                refundable, at the sole discretion of Cloudious, if you, the
                account holder, cancel within thirty (30) days after purchase.
                Only first-time Shared Hosting, Stellar, Stellar Plus, Stellar
                Business, Reseller Hosting, Nebula, Galaxy Expert, Universe Pro,
                VPS hosting, VPS Pulsar, VPS Quasar, and VPS Magnetar accounts
                are eligible for the 30-day money-back guarantee.
              </li>
              <li>
                Renewals of Web Hosting may be refundable, at the sole
                discretion of Cloudious, if you, the account holder, cancel your
                hosting within forty-eight (48) hours after the renewal. In
                other cases a prorated refund may be provided upon
                consideration.
              </li>
              <li>No refunds are available for the following:</li>
              <ul class="gb-list">
                <li>
                  Any additional features, services or upgrades added to your
                  Web Hosting package and purchased at additional fee;
                </li>
                <li>
                  Any fees related to domain name registrations associated with
                  your Web Hosting package;
                </li>
              </ul>
            </ul>
            <p><strong>Private Email Hosting</strong></p>
            <ul class="gb-list">
              <li>
                Unless this Refund Policy specifically provides for a refund,
                all fees for private email hosting and related Service(s) are
                non-refundable, in whole or in part, even if your private email
                hosting Service(s) are suspended or terminated prior to the end
                of your then current contract term.
              </li>
              <li>
                New purchase or renewal of a paid Private Email service may be
                refundable, at the sole discretion of Cloudious, if you, the
                account holder, contact us to cancel the Private Email
                subscription for a valid reason and the cancellation is
                requested within 7 days after the charge.
              </li>
            </ul>
            <p><strong>SSL Certificates</strong></p>
            <ul class="gb-list">
              <li>
                Unless this Refund Policy specifically provides for a refund,
                all fees for SSL Certificates are non-refundable, in whole or in
                part.
              </li>
              <li>
                If a refund is available, fees for SSL Certificates will be
                refunded in the form of an account credit.
              </li>
              <li>
                In order to qualify for a refund you must do one of the
                following within ninety (90) days after purchase: that is, you
                must 1) obtain issuance and thereafter make a written request
                for a refund within fifteen (15) days of that date; 2) attempt
                at issuance and be unsuccessful, in which case the refund will
                be credited to your account automatically; or 3) make no attempt
                at issuance but make a written refund request to Cloudious.
              </li>
            </ul>
            <p><strong>PremiumDNS</strong></p>
            <ul class="gb-list">
              <li>
                Unless this Refund Policy specifically provides for a refund,
                all fees for PremiumDNS and related Service(s) are
                non-refundable, in whole or in part, even if your PremiumDNS
                Service(s) are&nbsp;suspended, cancelled or transferred prior to
                the end of your then current contract term.
              </li>
              <li>
                Fees for the Services may be refundable, at the sole discretion
                of Cloudious, if you cancel the Services within five (5) days
                after purchase.
              </li>
            </ul>
            <p><strong>Apps Marketplace</strong></p>
            <ul class="gb-list">
              <li>
                In most cases, Apps purchased or renewed through our Apps
                Marketplace may be refundable, at the sole discretion of
                Cloudious, if you request cancellation and refund within
                twenty-four (24) hours after purchase/renewal.
              </li>
              <li>
                No refunds are available for Apps which provide an immediate,
                one-time service and/or incur fees directly related to the
                product provided: such as, but not limited to, Cloudious Legal
                and Business Card Maker.
              </li>
              <li>
                If you purchase an App that provides for a trial period, you
                must cancel within the trial period to be eligible for any
                available refunds. That is, if you participate in a free trial
                period and thereafter continue to access or use the App after
                the trial period has passed, no refund is available.
              </li>
            </ul>
            <p><strong>Non-refundable Services</strong></p>
            <p>
              All other Services are non-refundable, including but not limited
              to:
            </p>
            <ul class="gb-list">
              <li>G Suite upgrades and downgrades;</li>
              <li>Transfers or renewals of domain name registrations;</li>
              <li>Fees for recovery or reactivation of domain names;</li>
              <li>
                Any Services purchased or acquired at a reduced fee or on
                promotion; or
              </li>
              <li>
                Any fees paid by you to Cloudious for providing non-service
                related support.
              </li>
            </ul>
            <p><strong>Refund Requests</strong></p>
            <p>
              If you think you are eligible for a refund, you can&nbsp;<a
                href="#"
                target="_blank"
                >submit a helpdesk ticket</a
              >. You must include the following information in your request:
            </p>
            <ul class="gb-list">
              <li>Acknowledging that you have read our Refund Policy</li>
              <li>Why you are asking for a refund</li>
              <li>
                Transaction identifying information (e.g. account username,
                support pin, transaction number, domain name, date of purchase)
              </li>
            </ul>
            <p>
              Once submitted, we will review your request and may require that
              you provide additional information. You acknowledge that you must
              provide a timely response to information requested. Failure to
              provide the information we need to evaluate your request may
              result in a closure of your request. Therefore, we ask you to
              please check your inbox so that we can provide the best support
              possible.
            </p>
            <p><strong>Contacts</strong></p>
            <p>
              If you have questions about our Refund Policy, you can contact
              Cloudious at&nbsp;<a
                href="mailto: support@cloudious.net"
                target="_blank"
                >support@cloudious.net</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
