<template>
  <div>
    <router-view></router-view>
    <Footer />
  </div>
  <!-- <login></login> -->
  <!-- <signup></signup> -->
</template>

<script>
import Main from "./components/Main.vue";
import Signup from "./components/Signup.vue";
import Login from "./components/Login.vue";
import Footer from "./components/Footer.vue";

export default {
  components: {
    "main-page": Main,
    signup: Signup,
    login: Login,
    Footer,
  },
};
</script>

<style></style>
