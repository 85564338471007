<template>
  <div class="body">
    <div class="section-7 wf-section">
      <div class="signupcontainer w-container">
        <div class="w-layout-grid grid-11">
          <img
            src="images/3629651_812.jpg"
            loading="lazy"
            id="w-node-_865e6937-f8d4-58d8-87d4-c1bf3911619d-32194c8e"
            srcset="
              images/3629651_812-p-500.jpg   500w,
              images/3629651_812-p-800.jpg   800w,
              images/3629651_812-p-1080.jpg 1080w,
              images/3629651_812-p-1600.jpg 1600w,
              images/3629651_812-p-2000.jpg 2000w,
              images/3629651_812-p-2600.jpg 2600w,
              images/3629651_812-p-3200.jpg 3200w,
              images/3629651_812.jpg        6000w
            "
            sizes="100vw"
            alt=""
            class="image-17"
          />
          <div
            id="w-node-_56357a95-98a9-daae-24c3-02c93943aa4e-32194c8e"
            class="w-form"
          >
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              method="get"
              class="form-2"
            >
              <h1
                id="w-node-_55371158-b672-49ef-03d9-8d64e1479c04-32194c8e"
                class="formheading"
              >
                Log in
              </h1>
              <label for="email" class="field-label-2">Email Address</label
              ><input
                type="email"
                class="text-field w-input"
                maxlength="256"
                name="email"
                data-name="Email"
                placeholder="Email Address"
                id="email"
                required=""
              /><label for="field" class="field-label-2">Password</label
              ><input
                type="password"
                class="text-field w-input"
                maxlength="256"
                name="field"
                data-name="Field"
                placeholder="***********"
                id="field"
                required=""
              />
              <img v-show="submitted" class="loader" src="images/loading.gif">              
              <input
              v-on:click="showToast"
              v-show="!submitted"
                type="submit"
                value="Login in"
                data-wait="Please wait..."
                class="submit-button-2 login-btn w-button"
              />
              <div class="bottom">
                <p>Don't have an account?</p>
                <router-link to="/signup"><input
                  type="submit"
                  value="Sign up"
                  data-wait="Please wait..."
                  class="submit-button-2 w-button"
                /></router-link>
              </div>
            </form>

            <div class="success-message w-form-done">
              <div>
                Thank you for Signing Up. We will get in touch with you.
              </div>
            </div>
            <div class="error-message w-form-fail">
              <div class="text-block-7">
                Oops! Something went wrong while submitting the form.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            submitted: false
        }
    },
    methods: {
    showToast() {
      this.$toast.success("Please check your inbox for verification email.", {
        position: "top-center",
        timeout: 15000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
      this.submitted = true
    },
    }
};
</script>

<style scoped>
/* .submit-button-2{
    width: 100%;
} */
.bottom{
    margin-top: 40px;
} 
p {
  color: gray;
  font-size: 18px;
}
.submit-button-2{
    margin-top: 10px;
    background-color: white;
    border: 1px solid #0b0f3f;
}
.login-btn {
    background-color: #09ebaf;
    border: none;
}
</style>