<template>
  <div class="body">
    <div class="section-7 wf-section">
      <div class="signupcontainer w-container">
        <div class="w-layout-grid grid-11">
          <img
            src="images/3629651_812.jpg"
            loading="lazy"
            id="w-node-_865e6937-f8d4-58d8-87d4-c1bf3911619d-32194c8e"
            srcset="
              images/3629651_812-p-500.jpg   500w,
              images/3629651_812-p-800.jpg   800w,
              images/3629651_812-p-1080.jpg 1080w,
              images/3629651_812-p-1600.jpg 1600w,
              images/3629651_812-p-2000.jpg 2000w,
              images/3629651_812-p-2600.jpg 2600w,
              images/3629651_812-p-3200.jpg 3200w,
              images/3629651_812.jpg        6000w
            "
            sizes="100vw"
            alt=""
            class="image-17"
          />
          <div
            id="w-node-_56357a95-98a9-daae-24c3-02c93943aa4e-32194c8e"
            class="w-form"
          >
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              method="get"
              class="form-2"
            >
              <h1
                id="w-node-_55371158-b672-49ef-03d9-8d64e1479c04-32194c8e"
                class="formheading"
              >
                Sign up
              </h1>
              <label for="name" class="field-label-2">Name</label
              ><input
                type="text"
                class="text-field w-input"
                v-model="name"
                maxlength="256"
                name="name"
                data-name="Name"
                placeholder="Name"
                id="name"
                required=""
              /><label for="email" class="field-label-2">Email Address</label
              ><input
                type="email"
                class="text-field w-input"
                v-model="email"
                maxlength="256"
                name="email"
                data-name="Email"
                placeholder="Email Address"
                id="email"
                required=""
              /><label for="phone" class="field-label-2">Phone Number</label
              ><input
                type="tel"
                class="text-field w-input"
                v-model="phone"
                maxlength="256"
                name="phone"
                data-name="phone"
                placeholder="Phone Number"
                id="phone"
                required=""
              /><label for="field" class="field-label-2">Password</label
              ><input
                type="password"
                class="text-field w-input"
                v-model="password"
                maxlength="256"
                name="field"
                data-name="Field"
                placeholder="***********"
                id="field"
                required=""
              /><label for="field-2" class="field-label-2"
                >Confirm Password</label
              ><input
                type="password"
                class="text-field w-input"
                v-model="confirmPassword"
                maxlength="256"
                name="field-2"
                data-name="Field 2"
                placeholder="***********"
                id="field-2"
                required=""
              /><label class="w-checkbox checkbox-field"
                ><input
                  type="checkbox"
                  id="checkbox"
                  name="checkbox"
                  data-name="Checkbox"
                  required="true"
                  class="w-checkbox-input"
                /><span class="checkbox-label w-form-label" for="checkbox"
                  >I agree to Terms &amp; Conditions</span
                ></label
              >
              <div v-on:click="formValidation" class="submit-button-2 w-button">
                <font-awesome-icon v-show="loader" icon="fa-solid fa-circle-notch" spin size="lg" color="white"/>
                <span id="buttonText">start your free trial now</span>
              </div>
              
              <!-- <router-link to="/login"><input
                type="submit"
                value="Login in"
                data-wait="Please wait..."
                class="submit-button-2 login-btn w-button"
              /></router-link> -->
            </form>
            <div class="success-message w-form-done">
              <div>
                Thank you for Signing Up. We will get in touch with you.
              </div>
            </div>
            <div class="error-message w-form-fail">
              <div class="text-block-7">
                Oops! Something went wrong while submitting the form.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            loader: false, 
            name: null,
            email: null,
            phone: null,
            password: null,
            confirmPassword: null,
        }
    },
  methods: {
    showToast() {
      this.$toast.success("Please check your inbox for account details.", {
        position: "top-center",
        timeout: 15000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    showErrorToast() {
      this.$toast.error("Please fill the complete information", {
        position: "top-center",
        timeout: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    formValidation(e){
      this.loader = true
      setTimeout(() => {
        if (!this.name || !this.email || !this.phone || !this.password || !this.confirmPassword) {
          this.loader = false
          this.showErrorToast()
        }
        else{
          this.loader = false
          this.showToast()
        }
      }, 3000)
      e.preventDefault()
    },
  },
};
</script>

<style scoped>
#buttonText{
  margin-left: 7px;
}
</style>