import Main from "./components/Main.vue";
import Signup from "./components/Signup.vue";
import Login from "./components/Login.vue";
import Privacy from "./components/Privacy.vue";
import Terms from "./components/Terms.vue";
import Refund from "./components/Refund.vue";

export default [
  { path: "/", component: Main },
  { path: "/signup", component: Signup },
  // {path: '/login', component: Login}
  { path: "/privacy", component: Privacy },
  { path: "/terms", component: Terms },
  { path: "/refund", component: Refund },
];
