<template>
  <div class="body">
    <div class="introsection wf-section">
      <div class="othercontainer w-container">
        <div
          data-animation="default"
          data-collapse="all"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          class="navbar-2 w-nav"
        >
          <div class="othercontainer navcontainer w-container">
            <a href="#" class="w-nav-brand"
              ><img
                src="images/cloudious-new-logo.png"
                loading="lazy"
                width="125"
                alt=""
                class="image-15"
            /></a>
            <nav role="navigation" class="w-nav-menu">
              <div class="menutab">
                <a
                  href="https://platform.cloudious.net/#/login"
                  class="navbutton-copy w-button"
                  >Login</a
                >
                <!-- <a href="#pricing" class="navbutton-copy w-button">contact</a> -->
              </div>
            </nav>
            <div class="menu-button-2 w-nav-button">
              <a
                href="https://platform.cloudious.net/#/login"
                class="navbutton w-button"
                >Login</a
              >
              <!-- <a
                href="https://platform.cloudious.net/#/register?plan=professional"
                class="navbutton contactBtn w-button"
                style="margin-left: 10px"
                >Register</a
              > -->
              <img
                src="images/icons8-menu-64.png"
                loading="lazy"
                width="42"
                alt=""
                class="menuimg"
              />
            </div>
          </div>
        </div>
        <div class="div-block bottom">
          <h1 class="heading">Fast. Simple. Reliable.</h1>
          <h1 class="heading-2">
            Managed Cloud Hosting for Those on a Mission
          </h1>
          <p class="paragraph">
            Supercharge your websites with managed hosting trusted by many
            businesses that demand
            <strong>high performance</strong> and
            <strong>reliability</strong> for their online presence.
          </p>
          <div class="buttonsdiv">
            <a href="#pricing" class="button start w-button">start now</a>
            <a href="#pricing" class="button view w-button">view plans</a>
          </div>
        </div>
      </div>
    </div>
    <div class="statssection wf-section">
      <div class="scontainer w-container">
        <div class="div-block-10">
          <div class="sdiv">
            <div class="stext">#1</div>
            <div class="sline">MB Hosting Provider on G2</div>
          </div>
          <div class="line"></div>
          <div class="sdiv">
            <div class="stext">73</div>
            <div class="sline">Industry-leading NPS</div>
          </div>
          <div class="line"></div>
          <div class="sdiv">
            <div class="stext">570k+</div>
            <div class="sline">Supercharge Websites</div>
          </div>
          <div class="line"></div>
          <div class="sdiv last">
            <div class="stext">24/7</div>
            <div class="sline">Real-time Expert Support</div>
          </div>
        </div>
      </div>
    </div>
    <div class="discoversection wf-section">
      <div class="othercontainer w-container">
        <div class="discoverwrapper">
          <h1 class="title">Discover Possibilities, Not Hosting Worries</h1>
          <h5 class="subtitle">
            Cloudious removes the complexity of cloud hosting and lets you focus
            on what matters most: your business!<br />
          </h5>
          <div class="discoverbottom">
            <div class="discoverdiv">
              <img
                src="images/simple-blue-icon.svg"
                loading="lazy"
                width="55"
                alt=""
                class="discoverimg"
              />
              <h4 class="headline">Simplicity That Saves Time</h4>
              <p class="paragraph-3">
                Staging environment, managing backups,
                <strong>vertical scaling</strong>, or installing
                <strong>free SSL</strong>; every operation is a few clicks away,
                allowing you to get more work done in less time.
              </p>
            </div>
            <div class="discoverdiv">
              <img
                loading="lazy"
                width="55"
                src="images/performance-blue-icon.svg"
                alt=""
                class="discoverimg"
              />
              <h4 class="headline">Performance Without Bounds</h4>
              <p class="paragraph-3">
                A fully optimized stack which ensures peak website performance,
                resulting in faster growth and less frustration.
              </p>
            </div>
            <div class="discoverdiv">
              <img
                loading="lazy"
                width="55"
                src="images/flexible-performance-blue-icon.svg"
                alt=""
                class="discoverimg"
              />
              <h4 class="headline">Flexibility That Adapts</h4>
              <p class="paragraph-3">
                Choice for top tech stacks (Node JS, PHP, Dot Net Core, ASP,
                Magento & Wordpress) on any plan gives you freedom and control
                over your vision.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="platformsection wf-section">
      <div class="othercontainer w-container">
        <div class="platformwrapper">
          <h1 class="title">Innovative Platform Built to Empower Teams</h1>
          <h3 class="subtitle">
            Managed hosting that helps businesses unleash the full potential of
            their websites.<br />
          </h3>
          <div class="platformgrid">
            <div
              id="w-node-_2aac6e16-b517-c5cc-ba2e-2bda4de28d33-d7a8866a"
              class="pgridleft"
            >
              <div class="items">
                <img
                  src="images/livein-minutes-new.svg"
                  loading="lazy"
                  width="48"
                  alt=""
                  class="platformimg"
                />
                <div class="itemsright">
                  <h2 class="itemheading">Go Live in Minutes</h2>
                  <p class="paragraph-4">
                    A simple platform gives you the power to create and launch
                    your websites in a matter of a few minutes, not days.
                  </p>
                  <div class="linediv"></div>
                </div>
              </div>
              <div class="items">
                <img
                  src="images/manage-pro-new.svg"
                  loading="lazy"
                  width="48"
                  alt=""
                  class="platformimg"
                />
                <div class="itemsright">
                  <h2 class="itemheading">Manage Like a Pro</h2>
                  <p class="paragraph-4">
                    Managing your agency’s websites or the team you collaborate
                    is simple and efficient with Cloudious Web Services.
                  </p>
                  <div class="linediv"></div>
                </div>
              </div>
              <div class="items">
                <img
                  src="images/scale-success.svg"
                  loading="lazy"
                  width="48"
                  alt=""
                  class="platformimg"
                />
                <div class="itemsright">
                  <h2 class="itemheading">Scale to Success</h2>
                  <p class="paragraph-4">
                    Faster website performance, security &amp; 1-click scaling
                    lets you grow your business without bounds.<br />
                  </p>
                </div>
              </div>
            </div>
            <img
              src="images/73812-cloud-computing-security.gif"
              loading="lazy"
              id="w-node-_6667b20d-23e4-83d5-6dc1-eb1bcb529c28-d7a8866a"
              alt=""
            />
            <div
              id="w-node-_455ff4cd-c2c8-bca1-5367-0c805ada7846-d7a8866a"
              class="pgridright"
            >
              <div class="bar">
                <div class="bardots"></div>
                <div class="bardots"></div>
                <div class="bardots"></div>
              </div>
              <div class="appsclouddiv">
                <div class="platformrtext">Choice of Top Applications</div>
                <div class="imagesdiv">
                  <img
                    src="images/wordpress-logo-36x36-1.svg"
                    loading="lazy"
                    width="55"
                    alt=""
                    class="platformrimages"
                  /><img
                    src="images/laravel-logo.svg"
                    loading="lazy"
                    width="55"
                    alt=""
                    class="platformrimages"
                  /><img
                    src="images/magento-logo.svg"
                    loading="lazy"
                    width="55"
                    alt=""
                    class="platformrimages"
                  /><img
                    src="images/icons8-php-logo-80.png"
                    loading="lazy"
                    width="55"
                    alt=""
                    class="platformrimages"
                  />
                </div>
                <div class="platformrtext">Choice for Top Tech Stack</div>
                <div class="imagesdiv">
                  <img
                    src="images/icons8-mongodb-48.png"
                    loading="lazy"
                    width="53"
                    alt=""
                    class="platformrimages"
                  /><img
                    src="images/icons8-react-native-48.png"
                    loading="lazy"
                    width="53"
                    alt=""
                    class="platformrimages"
                  /><img
                    src="images/icons8-vue-js-48.png"
                    loading="lazy"
                    width="53"
                    alt=""
                    class="platformrimages"
                  /><img
                    src="images/icons8-nodejs-48.png"
                    loading="lazy"
                    width="53"
                    alt=""
                    class="platformrimages"
                  />
                </div>
                <div class="scaletext">Scale at will</div>
                <div class="imagesdiv-copy">
                  <div class="scale"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="trialsection wf-section">
      <div class="trialcont w-container">
        <h1 class="trialheading">Get Higher ROI From Your Websites</h1>
        <p class="trialtag">
          With Cloudious, your websites are faster, more secure, and backed by
          24/7 real-time expert support.
        </p>
        <a href="#pricing" class="trialbtn w-button">start now</a>
      </div>
    </div>
    <div class="supportsection wf-section">
      <div class="othercontainer w-container">
        <div class="w-layout-grid supportgrid">
          <img
            src="images/new-advance-support-cw.svg"
            loading="lazy"
            id="w-node-_2ee3303c-0919-a106-fb9b-a6ef9aeff025-d7a8866a"
            alt=""
            class="image-10"
          />
          <div
            id="w-node-_1d9411a9-0499-28ab-7fb3-c4b2ecd2b148-d7a8866a"
            class="supportdiv"
          >
            <h1 class="supportheading">Save Time With 24/7 Expert Support</h1>
            <p class="supportpara">
              You get 24/7/365 expert support for solving problems on the spot.
              And if you need a closer partnership, you can choose the
              <strong>Advanced Support add-on</strong> for enhanced support or
              the <strong>Premium Support add-on</strong>, which gives you
              access to our Senior Support Engineers, who act as an extension of
              your in-house team.
            </p>
            <!-- <a href="#pricing" class="explorebtn w-button">explore now</a> -->
          </div>
        </div>
      </div>
    </div>
    <div id="pricing">
      <stripe-pricing-table
        pricing-table-id="prctbl_1PMd5gRwh8JfPwR9EAqI9ljv"
        publishable-key="pk_live_51OwUwlRwh8JfPwR9vIXWccVRVTVNke3hzVqQhV7WyzKlSnXJgZA0ufS7Pry0PI26juZiZfMbKG8p14A2AkfsKoJT006AuiQl6H"
      >
      </stripe-pricing-table>
    </div>

    <div class="langsection wf-section">
      <div class="othercontainer w-container">
        <div class="langwrapper">
          <h1 class="title">Your Favorite Apps, Optimized to The Max</h1>
          <h1 class="subtitle">
            A highly efficient &amp; powerful stack lies at the core of every
            app you launch, which means your websites are super fast and run
            without restraints.
          </h1>
          <div class="langdiv">
            <div class="w-layout-grid langsgrid">
              <div
                id="w-node-_821c8874-d488-71d0-8ded-f824ed215648-d7a8866a"
                class="langnamediv"
              >
                <img
                  src="images/icons8-nodejs-48.png"
                  loading="lazy"
                  alt=""
                  class="langimg"
                />
                <div class="langname">Node JS</div>
              </div>
              <div
                id="w-node-_81602303-3dbb-de8f-d52e-68ebc684a6a7-d7a8866a"
                class="langnamediv mob"
              >
                <img
                  src="images/icons8-vue-js-48.png"
                  loading="lazy"
                  width="40"
                  height="36"
                  alt=""
                  class="langimg"
                />
                <div class="langname">Vue JS</div>
              </div>
              <div
                id="w-node-_068ac0ae-dfc9-1a48-e5ed-30d3edc1bf4e-d7a8866a"
                class="langnamediv"
              >
                <img
                  src="images/laravel-logo.svg"
                  loading="lazy"
                  width="38"
                  id="w-node-_7cc004b3-fa9f-b3ae-95b7-8cc63c26757f-d7a8866a"
                  alt=""
                  class="langimg"
                />
                <div class="langname">Laravel</div>
              </div>
              <div
                id="w-node-a6359995-e8d8-f225-04f2-cc01ce83ef39-d7a8866a"
                class="langnamediv mob"
              >
                <img
                  src="images/icons8-php-logo-80.png"
                  loading="lazy"
                  width="50"
                  alt=""
                />
                <div class="langname">PHP</div>
              </div>
            </div>
            <div class="w-layout-grid langbottomgrid">
              <div
                id="w-node-_51b0124d-6add-d7af-b8bb-d278570e49e8-d7a8866a"
                class="div-block-8"
              >
                <h1 class="heading-7">
                  Best Managed Cloud Hosting That Guarantees Success
                </h1>
                <p class="paragraph-7">
                  Removing the complexity around cloud hosting allows you to
                  scale your websites and web APIs how you want with our managed
                  hosting . The simple-to-use Managed Web Hosting features
                  include advanced caches coupled with Breeze, a simplified
                  Cloudious cache, and Cloudflare Enterprise for faster
                  performance &amp; added security. They all come together to
                  provide a seamless web hosting experience.
                </p>
                <a href="#pricing" class="langlink">start now</a>
              </div>
              <img
                src="images/new-advance-support-cw.svg"
                loading="lazy"
                id="w-node-f25d2151-736e-a590-204f-c01230bc48d0-d7a8866a"
                alt=""
                class="image-10"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="testimonial-section wf-section">
      <h2 class="title">Here’s Why Our Customers Love Us</h2>
      <h1 class="subtitle">
        From 24/7 support that acts as your extended team to incredibly fast
        website performance, there are plenty of reasons why our users keep
        recommending us!
      </h1>
      <div class="testimonialgrid">
        <div
          id="w-node-_9304260b-f386-5cae-d9bf-9515806631a2-d7a8866a"
          class="testimonialcard"
        >
          <p class="testimonialtext">
            The site’s loading time was greatly improved… I have a total of 14
            clients on the platform and am in the process of migrating more.
          </p>
          <div class="author">
            <img
              src="images/avatar-2ed1503681288bb665ca8b14db010ab4.jpg"
              loading="lazy"
              srcset="
                images/avatar-2ed1503681288bb665ca8b14db010ab4-p-500.jpg  500w,
                images/avatar-2ed1503681288bb665ca8b14db010ab4-p-800.jpg  800w,
                images/avatar-2ed1503681288bb665ca8b14db010ab4.jpg       1024w
              "
              sizes="100vw"
              alt=""
              class="authorimg"
            />
            <div class="authorinfo">
              <h3 class="authorname">Martha Sam</h3>
              <div class="authorabout">President &amp; Founder</div>
            </div>
          </div>
        </div>
        <div
          id="w-node-_9304260b-f386-5cae-d9bf-9515806631ac-d7a8866a"
          class="testimonialcard"
        >
          <p class="testimonialtext">
            Excellent option for those looking for High-End WordPress Hosting. I
            have been using this platform for two years and the performance in
            amazing.
          </p>
          <div class="author">
            <img
              src="images/avatar-aa0f490ddb16c2cd6b0fc81faebf3cfd.jpg"
              loading="lazy"
              srcset="
                images/avatar-aa0f490ddb16c2cd6b0fc81faebf3cfd-p-500.jpg  500w,
                images/avatar-aa0f490ddb16c2cd6b0fc81faebf3cfd-p-800.jpg  800w,
                images/avatar-aa0f490ddb16c2cd6b0fc81faebf3cfd.jpg       1024w
              "
              sizes="100vw"
              alt=""
              class="authorimg"
            />
            <div class="authorinfo">
              <h3 class="authorname">John Gel</h3>
              <div class="authorabout">Small Businnss owner</div>
            </div>
          </div>
        </div>
        <div
          id="w-node-_9304260b-f386-5cae-d9bf-9515806631b6-d7a8866a"
          class="testimonialcard"
        >
          <p class="testimonialtext">
            Digital agencies like ours get to have multiple servers from
            different providers in one place
          </p>
          <div class="author">
            <img
              src="images/avatar-40db4d77839edd45f535d9152f70c8f9.jpg"
              loading="lazy"
              srcset="
                images/avatar-40db4d77839edd45f535d9152f70c8f9-p-500.jpg  500w,
                images/avatar-40db4d77839edd45f535d9152f70c8f9-p-800.jpg  800w,
                images/avatar-40db4d77839edd45f535d9152f70c8f9.jpg       1024w
              "
              sizes="100vw"
              alt=""
              class="authorimg"
            />
            <div class="authorinfo">
              <h3 class="authorname">Mike Wright</h3>
              <div class="authorabout">@Mike32</div>
            </div>
          </div>
        </div>
        <div
          id="w-node-_0b464219-36d8-f6c7-69dd-dbfd9895e5b2-d7a8866a"
          class="testimonialcard"
        >
          <p class="testimonialtext">
            Gamechanger… completely elevated my ability to service my clients.
            Their support staff are extremely knowledgeable and the platform
            offers all the flexibility and scalability.
          </p>
          <div class="author">
            <img
              src="images/avatar-c7ab7b6f83ec92b55c03d31239838298.jpg"
              loading="lazy"
              srcset="
                images/avatar-c7ab7b6f83ec92b55c03d31239838298-p-500.jpg  500w,
                images/avatar-c7ab7b6f83ec92b55c03d31239838298-p-800.jpg  800w,
                images/avatar-c7ab7b6f83ec92b55c03d31239838298.jpg       1024w
              "
              sizes="100vw"
              alt=""
              class="authorimg"
            />
            <div class="authorinfo">
              <h3 class="authorname">Jessica R</h3>
              <div class="authorabout">Photographer</div>
            </div>
          </div>
        </div>
        <div
          id="w-node-_616f6357-1ba8-d8e1-cbcf-40bd76127688-d7a8866a"
          class="testimonialcard"
        >
          <p class="testimonialtext">
            A simple management dashboard, combined with amazing chat support,
            reduces costs for outsourced server ops services.
          </p>
          <div class="author">
            <img
              src="images/avatar-e2ad19fd1789d2861b7e072fe2682276.jpg"
              loading="lazy"
              srcset="
                images/avatar-e2ad19fd1789d2861b7e072fe2682276-p-500.jpg  500w,
                images/avatar-e2ad19fd1789d2861b7e072fe2682276-p-800.jpg  800w,
                images/avatar-e2ad19fd1789d2861b7e072fe2682276.jpg       1024w
              "
              sizes="100vw"
              alt=""
              class="authorimg"
            />
            <div class="authorinfo">
              <h3 class="authorname">Maria Smith</h3>
              <div class="authorabout">Small Businnss owner</div>
            </div>
          </div>
        </div>
        <div
          id="w-node-_92375988-256e-3da6-1983-f6282e4f188d-d7a8866a"
          class="testimonialcard"
        >
          <p class="testimonialtext">
            Digital agencies like ours get to have multiple servers from
            different providers in one place
          </p>
          <div class="author">
            <img
              src="images/avatar-85a90db4c006ba247067f2801a975cc9.jpg"
              loading="lazy"
              srcset="
                images/avatar-85a90db4c006ba247067f2801a975cc9-p-500.jpg  500w,
                images/avatar-85a90db4c006ba247067f2801a975cc9-p-800.jpg  800w,
                images/avatar-85a90db4c006ba247067f2801a975cc9.jpg       1024w
              "
              sizes="100vw"
              alt=""
              class="authorimg"
            />
            <div class="authorinfo">
              <h3 class="authorname">Walter</h3>
              <div class="authorabout">@Walter.S</div>
            </div>
          </div>
        </div>
        <div
          id="w-node-_5dde1ce7-9158-90ee-1cf3-653860cf836c-d7a8866a"
          class="testimonialcard"
        >
          <p class="testimonialtext">
            I am using Professional Plan in Cloudious and I can confirm it is
            very good. Also, additional the backup with my hosting is awesome
            too.
          </p>
          <div class="author">
            <img
              src="images/avatar-da57991833c6b018b7c4953363263dd0.jpg"
              loading="lazy"
              srcset="
                images/avatar-da57991833c6b018b7c4953363263dd0-p-500.jpg  500w,
                images/avatar-da57991833c6b018b7c4953363263dd0-p-800.jpg  800w,
                images/avatar-da57991833c6b018b7c4953363263dd0.jpg       1024w
              "
              sizes="100vw"
              alt=""
              class="authorimg"
            />
            <div class="authorinfo">
              <h3 class="authorname">Elizabeth</h3>
              <div class="authorabout">@Eliz.M9</div>
            </div>
          </div>
        </div>
        <div
          id="w-node-f7bd75b8-ff1c-ab41-dd08-7631dd23eee2-d7a8866a"
          class="testimonialcard"
        >
          <p class="testimonialtext">
            Cloudious is super easy, super fast and cost effective.
          </p>
          <div class="author">
            <img
              src="images/avatar-423479ef491efc0f65cf19f0c2332488.jpg"
              loading="lazy"
              srcset="
                images/avatar-423479ef491efc0f65cf19f0c2332488-p-500.jpg  500w,
                images/avatar-423479ef491efc0f65cf19f0c2332488-p-800.jpg  800w,
                images/avatar-423479ef491efc0f65cf19f0c2332488.jpg       1024w
              "
              sizes="100vw"
              alt=""
              class="authorimg"
            />
            <div class="authorinfo">
              <h3 class="authorname">Stella Shawn</h3>
              <div class="authorabout">Small Business Owner</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section-5 wf-section">
      <div class="container-2 w-container">
        <div class="div-block">
          <h1 class="heading-2">Quit Worrying About Hosting Hassles</h1>
          <p class="paragraph">
            Host phenomenal websites on a managed platform built for digital
            agencies, developers, and ecommerce businesses, all backed by
            24/7/365 support.
          </p>
          <div class="buttonsdiv">
            <a href="#pricing" class="freetrialbtn w-button">start now</a>
            <a href="#pricing" class="planbtn w-button">see all plans</a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer wf-section">
      <div class="container-2 w-container">
        <div class="w-layout-grid grid-10">
          <div class="footerdiv">
            <img
              src="images/cloudious-new-logo.png"
              loading="lazy"
              width="230"
              alt=""
              class="image-14"
            />
            <div class="sociallinks">
              <img
                src="images/LinkedIn.svg"
                loading="lazy"
                alt=""
                class="socialicon"
              /><img
                src="images/icon-facebook.svg"
                loading="lazy"
                width="26"
                alt=""
                class="socialicon"
              /><img
                src="images/Twitter.svg"
                loading="lazy"
                alt=""
                class="socialicon"
              />
            </div>
          </div>
          <div
            id="w-node-a7b693a3-85b6-4b67-48ce-e3c6685935de-d7a8866a"
            class="footerdiv"
          >
            <h4 class="footerheading">Product &amp; Solution</h4>
            <ul role="list" class="w-list-unstyled">
              <li class="list-item">
                <a href="#" class="footerlink">WordPress Hosting</a>
              </li>
              <li class="list-item">
                <a href="#" class="footerlink">WooCommerce Hosting</a>
              </li>
              <li class="list-item">
                <a href="#" class="footerlink">PHP Hosting</a>
              </li>
              <li class="list-item">
                <a href="#" class="footerlink">Magento Hosting</a>
              </li>
              <li class="list-item">
                <a href="#" class="footerlink">Laravel Hosting </a>
              </li>
              <li class="list-item">
                <a href="#" class="footerlink">Ecommerce Hosting</a>
              </li>
              <li class="list-item">
                <a href="#" class="footerlink">Reseller Hosting</a>
              </li>
              <li class="list-item">
                <a href="#" class="footerlink">Managed Amazon Cloud</a>
              </li>
              <li class="list-item">
                <a href="#" class="footerlink">Managed Google Cloud</a>
              </li>
              <li class="list-item">
                <a href="#" class="footerlink">Managed Digital Ocean</a>
              </li>
              <li class="list-item">
                <a href="#" class="footerlink">Affiliate Program</a>
              </li>
              <li class="list-item">
                <a href="#" class="footerlink">Partnership Program</a>
              </li>
            </ul>
          </div>
          <div
            id="w-node-b1396da2-d99a-0a46-164b-65938d90333a-d7a8866a"
            class="footerdiv"
          >
            <h4 class="footerheading">Support</h4>
            <ul role="list" class="w-list-unstyled">
              <li class="list-item">
                <a href="#" class="footerlink">Our Support</a>
              </li>
              <li class="list-item">
                <a href="#" class="footerlink">System Status</a>
              </li>
              <li class="list-item">
                <a href="#" class="footerlink">Knowledge base</a>
              </li>
              <li class="list-item">
                <a href="#" class="footerlink">Blog</a>
              </li>
              <li class="list-item">
                <a href="#" class="footerlink">Case Studies &amp; Ebooks</a>
              </li>
              <li class="list-item">
                <a href="#" class="footerlink">Customer Voice</a>
              </li>
              <li class="list-item">
                <a href="mailto:support@cloudious.net" class="footerlink"
                  >Contact Us</a
                >
              </li>
              <li class="list-item">
                <a href="#" class="footerlink">Write for us</a>
              </li>
            </ul>
          </div>
          <div
            id="w-node-_90adc38a-3222-c22f-873d-263ee963a74b-d7a8866a"
            class="footerdiv"
          >
            <h4 class="footerheading">Company</h4>
            <ul role="list" class="w-list-unstyled">
              <li class="list-item">
                <a href="#" class="footerlink">About Us</a>
              </li>
              <li class="list-item">
                <a href="#" class="footerlink">Careers</a>
              </li>
              <li class="list-item">
                <a href="#" class="footerlink">Customer Reviews</a>
              </li>
              <li class="list-item">
                <a href="#" class="footerlink">Media Kit</a>
              </li>
              <li class="list-item">
                <a href="#" class="footerlink">Terms</a>
              </li>
              <li class="list-item">
                <a href="#" class="footerlink">Promo Code</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      plan: "",
    };
  },
  methods: {
    setPlan(p) {
      this.plan = p;
    },
  },
};
</script>

<style scoped>
.discount-pill {
  background-color: #4caf50; /* Green background color */
  color: white; /* Text color */
  border-radius: 20px; /* Make it pill-shaped */
  padding-left: 15px;
  padding-right: 15px;
  display: inline-block; /* Make it inline */
  font-size: 12px; /* Font size */
  height: auto;
}
</style>
