<template>
  <div class="section-6 wf-section">
    <div class="w-container">
      <div class="policyDiv">
        <router-link to="/privacy" class="policy-link"
          >Privacy Policy</router-link
        >
        <router-link to="/terms" class="policy-link"
          >Terms & Conditions</router-link
        >
        <router-link to="/refund" class="policy-link"
          >Refund Policy</router-link
        >
      </div>
      <div class="text-block-5">All Rights Reserved 2024</div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
