<template>
  <div>
    <div class="introsection wf-section">
      <div class="othercontainer w-container">
        <div
          data-animation="default"
          data-collapse="all"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          class="navbar-2 w-nav"
        >
          <div class="othercontainer navcontainer w-container">
            <a href="/" class="w-nav-brand"
              ><img
                src="images/cloudious-new-logo.png"
                loading="lazy"
                width="125"
                alt=""
                class="image-15"
            /></a>
            <nav role="navigation" class="w-nav-menu">
              <div class="menutab">
                <a
                  href="https://platform.cloudious.net/#/login"
                  class="navbutton-copy w-button"
                  >Login</a
                >
              </div>
            </nav>
            <div class="menu-button-2 w-nav-button">
              <a
                href="https://platform.cloudious.net/#/login"
                class="navbutton w-button"
                >Login</a
              >
              <a
                href="mailto: support@cloudious.net"
                class="navbutton contactBtn w-button"
                style="margin-left: 10px"
                >contact</a
              >
              <img
                src="images/icons8-menu-64.png"
                loading="lazy"
                width="42"
                alt=""
                class="menuimg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="general-section privacy-section wf-section">
      <div class="othercontainer w-container">
        <h1 class="privacy-title">Terms & Conditions</h1>
        <div class="privacy-content">
          <p>
            This Universal Terms of Service Agreement ("Agreement") sets forth
            the terms and conditions of the use of our systems, software,
            platforms, APIs, and the use and/or purchase of our products and
            related services and for the purchase and/or use of any products and
            services acquired through Cloudious from our partners and/or
            affiliates (collectively "Services").
          </p>
          <p>
            In this Agreement "You" and "Your" refer to You as the user of our
            Services, or any agent, employee, servant or person authorized to
            act on Your behalf. "We", "Us" and "Our" refer to Cloudious, Inc.,
            as well as its subsidiaries and sister companies (“Cloudious”). This
            Agreement explains Our obligations to You, and explains Your
            obligations to Us for using Our Services. These obligations are in
            addition to (not in lieu of) any specific terms and conditions that
            apply to the particular Services.
          </p>
          <p>
            When You use Our site, Your account (or You permit someone else to
            use it) to purchase or otherwise acquire access to Services or to
            cancel Your Services (even if We were not notified of such
            authorization), You signify Your agreement to the terms and
            conditions contained in this Agreement, along with the following
            policies and the applicable product agreements, which are
            incorporated by reference herein.
          </p>

          <h2>1. Term of Agreement; Modification.</h2>
          <p>
            You agree that Cloudious may, in its sole and absolute discretion,
            modify this Agreement and the Services it offers to You from time to
            time and that such modifications are effective immediately upon
            posting to this site. Your use of the site or Services, after such
            changes or modifications have been made, constitutes Your agreement
            to be bound by this Agreement as last revised. If You have purchased
            Services from Cloudious, the terms and conditions of this Agreement
            shall continue in full force and effect, including any changes made
            to this Agreement, as long as You take advantage of and use the
            Services.
          </p>
          <h2>2. Eligibility &amp; Authority.</h2>
          <p>
            Our site and Services are available only to users who can form
            legally binding contracts under applicable law. By using this site
            and/or the Services, You represent and warrant that you are at least
            eighteen (18) years of age and not a person barred from purchasing
            or receiving the Services found under the laws of the United States
            or other applicable jurisdiction.
          </p>
          <p>
            If You are entering this Agreement on behalf of a corporate entity,
            You represent and warrant that You have legal authority to bind such
            corporate entity to the terms and conditions contained in this
            Agreement (and those additional agreements and policies incorporated
            by reference), in which case “You”, “Your” will also refer to such
            corporate entity. If, after acceptance of this Agreement, We find
            that You do not have the legal authority to bind said corporate
            entity, You will be personally responsible for the obligations
            contained in this Agreement and any/all related agreements that You
            enter into based on the Services You use, including but not limited
            to the payment obligations. Cloudious shall not be liable for any
            loss or damage resulting from Our reliance on any instruction,
            notice, document or communication reasonably believed by Us to be
            genuine and originating from an authorized representative of Your
            corporate entity. If there is reasonable doubt about the
            authenticity of any such instruction, notice, document or
            communication, We reserve the right (but undertake no duty) to
            require additional authentication from You. You further agree to be
            bound by the terms of this Agreement, and any other agreement and/or
            policies required by the Services purchased, for transactions
            entered into by You, anyone acting as Your agent and anyone who uses
            Your account or the Services, whether or not authorized by You.
          </p>
          <h2>3. Accounts; Accurate Information; Transfer of Data Abroad.</h2>
          <p>
            <strong>Accounts &amp; Accurate Information.</strong>&nbsp;In order
            to access some of the features of this site or use some of the
            Services, You will have to create an Account. You represent and
            warrant that all information You submit when you create your Account
            is accurate, current and complete, and that you will keep your
            Account information accurate, current and complete. You further
            agree to maintain accurate information by providing updates to Us,
            as needed, while You are using the Services. You agree that You will
            notify Cloudious within five (5) business days of any change in the
            information You provided as part of the application and/or
            registration process and as required by Your Account. Failure by
            You, for whatever reason, to respond within five (5) business days
            to any inquiries made by Cloudious to determine the validity of
            information provided by You will constitute a material breach of
            this Agreement.
          </p>
          <p>
            If We have reason to believe that Your Account information is
            untrue, inaccurate, not current, misleading or incomplete, We
            reserve the right, in Our sole and absolute discretion, to suspend
            or terminate your Account and any Services.
          </p>
          <p>
            You are solely responsible for the activity that occurs on Your
            Account, whether authorized by You or not, and You must keep your
            Account information secure, including without limitation Your
            customer username/login, support pin code, password, API key (if
            any) and any/all content which might include payment details.
          </p>
          <p>
            You must notify Cloudious immediately of any breach of security or
            unauthorized use of Your Account. We will not be liable for any loss
            You incur due to any unauthorized use of Your Account. You, however,
            may be liable for any loss We or others incur caused by Your
            Account, whether caused by You, or by an authorized person, or by an
            unauthorized person. Further, You agree that We may charge You
            administrative fees equal to $50 (US Dollars) per hour for Our time
            spent in relation to said matter, regardless of whether or not We
            return control over the Account and/or domain names in question to
            You.
          </p>
          <p>
            <strong>Transfer of Data Abroad.</strong>&nbsp;If you are visiting
            this Site and/or using Our Services from a country other than the
            country in which Our servers are located, Your communications with
            Us and/or use of Our Services may result in the transfer of personal
            information (including Your Account information) across
            international boundaries. By visiting this Site, using Our Services
            and communicating electronically with Us, You consent to such
            transfers.
          </p>
          <h2>4. Data Protection, Use &amp; Processing.</h2>
          <p>
            Cloudious offers certain hosted Services available to you that may
            involve the submission, collection and/or use of personally
            identifying or identifiable information about you and your own
            customers (“Your Data”) in the course of your use of these Services
            (“Covered Services”). Your Data, for the purpose of this Section,
            excludes any User Content. Cloudious’s Controller to Processor Data
            Processing Addendum (“DPA”), which is hereby incorporated by
            reference and applicable to Covered Services, is meant to provide
            you contractual assurance that we have robust mechanisms to ensure
            the processing of Your Data, including transfers of Your Data from
            the EEA is compliant with applicable data privacy laws.
          </p>
          <p>
            For the purposes of the Controller to Processor DPA and the Standard
            Contractual Clauses attached to the DPA (when and as applicable),
            you (and your applicable affiliates) are considered the Data
            Controller/Data Exporter, and your acceptance of the terms of
            service governing Covered Services at the time of purchase of any
            Covered Services will also be treated as your acknowledgment and
            acceptance of the DPA and its appendices (including the Standard
            Contractual Clauses and its appendices, as applicable). If you wish
            to print, sign and return a physical copy of the Controller to
            Processor DPA, please send an email request to dpa@Cloudious.com
          </p>
          <p>
            Covered Services, as defined in this Section and in the Controller
            to Processor DPA, include hosted services that are subject to the
            terms and conditions of the following Agreements: (1)&nbsp;<a
              href="#"
              target="_blank"
              >Domain Registration Agreement</a
            >, (2)&nbsp;<a href="#" target="_blank"
              >Private Email Terms of Service</a
            >, (3)&nbsp;<a href="#" target="_blank">Hosting Terms of Service</a
            >, (4)&nbsp;<a href="#" target="_blank"
              >PremiumDNS Terms of Service</a
            >, (5)&nbsp;<a href="#" target="_blank"
              >EasyWP Ghost Machine Terms of Service</a
            >, (6)&nbsp;<a href="#" target="_blank"
              >Whois Privacy Service Agreement</a
            >&nbsp;and (7)&nbsp;<a href="#" target="_blank"
              >SSL Certificates Service Agreement</a
            >
          </p>
          <h2>5. Availability of Website &amp; Services.</h2>
          <p>
            We shall use commercially reasonable efforts to attempt to provide
            this site, the Services available to purchase on Our site and Our
            Services on a twenty-four (24) hours a day, seven (7) days a week
            basis. You acknowledge and agree that from time to time this site
            may be inaccessible or inoperable for any reason including, but not
            limited to, equipment malfunctions; periodic maintenance, repairs or
            replacements that We undertake from time to time; or causes beyond
            our reasonable control or that are not reasonably foreseeable
            including, but not limited to, interruption or failure of
            telecommunication or digital transmission links, hostile network
            attacks, network congestion or other failures. You acknowledge and
            agree that We have no control over the availability of this Site or
            Services on a continuous or uninterrupted basis, and that We assume
            no liability to You or any other party with regard to such,
            including but not limited to loss of revenue.
          </p>
          <p>
            From time to time, Cloudious may offer new Services (limited preview
            services or new features to existing Services) in a pre-release
            version. New Services, new features to existing Services or limited
            preview services shall be known, individually and collectively, as
            “Beta Services”. Beta Services, unless otherwise exempted, are
            subject to the following:
          </p>
          <p>
            If You elect to use any Beta Services, then your use of the Beta
            Services is subject to the following terms and conditions: (i) You
            acknowledge and agree that the Beta Services are pre-release
            versions and may not work properly; (ii) You acknowledge and agree
            that Your use of the Beta Services may expose You to unusual risks
            of operational failures; (iii) The Beta Services are provided as is,
            so We do not recommend using them in production or mission critical
            environments; (iv) We reserve the right to modify, change, or
            discontinue any aspect of the Beta Services at any time; (v)
            Commercially released versions of the Beta Services may change
            substantially, and programs that use or run with the Beta Services
            may not work with the commercially released versions or subsequent
            releases; (vi) Cloudious may limit availability of customer service
            support time dedicated to support of the Beta Services; (vii) You
            acknowledge and agree to provide prompt feedback regarding Your
            experience with the Beta Services in a form reasonably requested by
            Us, including information necessary to enable us to duplicate errors
            or problems you experience. You acknowledge and agree that we may
            use Your feedback for any purpose, including product development
            purposes. At Our request You will provide Us with comments that We
            may use publicly for press materials and marketing collateral. Any
            intellectual property inherent in Your feedback or arising from Your
            use of the Beta Services shall be owned exclusively by Cloudious;
            (viii) You acknowledge and agree that all information regarding Your
            use of the Beta Services, including Your experience with and
            opinions regarding the Beta Services, is confidential, and may not
            be disclosed to a third party or used for any purpose other than
            providing feedback to Cloudious; (ix) the Beta Services are provided
            “as is”, “as available”, and “with all faults”. To the fullest
            extent permitted by law, Cloudious disclaims any and all warranties,
            statutory, express or implied, with respect to the Beta Services
            including, but not limited to, any implied warranties of title,
            merchantability, fitness for a particular purpose and
            non-infringement.
          </p>
          <p>
            Cloudious offers, as part of the Services available to You, products
            and/or services from third parties. These products and/or services
            are subject to the terms of this Agreement, including any additional
            policies and agreements required, and this Agreement controls as
            between You and Cloudious. Nothing contained in any agreement
            executed between You and a third party provider shall eliminate,
            reduce or add to the obligations of Cloudious as described herein.
          </p>
          <p>
            All paid Services are non-refundable, unless otherwise specified.
          </p>
          <h2 id="n-6-account-panel">6. Account Panel.</h2>
          <p>
            The account panel associated with Your Account (“Account Panel”)
            enables You to purchase, renew, extend, suspend or cancel
            Service(s). You acknowledge and agree that You will not use the
            Account Panel to abuse and/or overload any of Our Systems or
            Services or any API. Abuse of the Account Panel will be determined
            in Our sole and absolute discretion. It includes but is not limited
            to repetitive, high volume requests, inquiries, calls or other
            excessive use or abuse of Our Systems or Service(s) or any API. You
            further agree to abide by such guidelines on acceptable use of Our
            Services (and/or the Account Panel) as set forth by Us, which may
            change at any time in Our sole and absolute discretion. In addition
            to any other right to terminate, Cloudious specifically has the
            right to immediately terminate Your Account, without notice or right
            to cure, in the event that You violate this term.
          </p>
          <h2 id="n-7-acceptable-use-policy-aup">
            7. Acceptable Use Policy (AUP).
          </h2>
          <p>
            You acknowledge and agree to the following with respect to all of
            Our Services:
          </p>
          <p>
            A. You will use all Services for lawful purposes only and You will
            comply with the terms of this Agreement and any other agreements you
            have entered into by virtue of purchasing or using Our Service(s) in
            addition to all applicable local, state, national and international
            laws, rules and regulations.
          </p>
          <p>
            B. You will not collect or harvest (or permit anyone else to collect
            or harvest) any user content (as defined below) or any non-public or
            personally identifiable information about any other user or any
            other person or entity without their express permission.
          </p>
          <p>
            C. You will NOT use our site or Services in a manner (as determined
            by Us in our sole and absolute discretion) that:
          </p>
          <ul class="gb-list">
            <li>
              Violates the laws, regulations, ordinances or other such
              requirements of any applicable Federal, State or local government
              and/or international laws or customary industry acceptable use
              standards;
            </li>
            <li>
              Promotes, encourages or engages in child pornography or the
              exploitation of children;
            </li>
            <li>
              Promotes, encourages, engages or displays cruelty to humans or
              animals;
            </li>
            <li>
              Promotes, encourages or engages in terrorism, violence or hatred
              against people, animals or property;
            </li>
            <li>
              Transmits any unsolicited commercial or bulk email, or engages in
              any activity known or considered to be spamming or Mail Bombing;
            </li>
            <li>
              Makes any illegal communication to any Newsgroup, Mailing List,
              Chat Facility, or another Internet Forum;
            </li>
            <li>
              Makes, attempts or allows any unauthorized access to Cloudious
              website, servers, account, Your own hosting account or the account
              of any other customers of Cloudious;
            </li>
            <li>
              Allows any remote code execution of malicious software through a
              hosting account or any APIs provided by Cloudious;
            </li>
            <li>
              Causes denial of service attacks, port scans or other endangering
              and invasive procedures against Cloudious servers and facilities
              or the servers and facilities of other network hosts or Internet
              users;
            </li>
            <li>
              Forges the signature or other identifying mark or code of any
              other person or engage in any activity to attempt to deceive other
              persons regarding the true identity of the user;
            </li>
            <li>
              Infringes any copyright, trademark, patent, trade secret, or other
              proprietary rights of any third party;
            </li>
            <li>
              Contains viruses, Trojan horses, worms, time bombs, corrupted
              files, or any other similar software or programs designed to, or
              capable of disrupting, damaging or limiting the functionality of
              any software or hardware;
            </li>
            <li>
              Contains any kind of proxy server or other traffic relaying
              programs;
            </li>
            <li>
              Uploads unacceptable material which include: IRC bots, warez,
              image, file storage, mirror, or banner-ad services, topsites,
              streaming, Escrow, High-Yield Interest Programs (HYIP) or related
              sites, investment sites (FOREX, E-Gold Exchange, etc.),
              cryptocurrency miners, sale of any controlled substances without
              providing proof of appropriate permit(s) in advance, AutoSurf
              sites, Bank Debentures, Bank Debenture Trading Programs, Prime
              Banks Programs, lottery sites, muds / rpg's, hate sites, hacking
              focused sites/archives/programs, or sites promoting illegal
              activities, IP Scanners, Brute Force Programs, Mail Bombers and
              Spam Scripts;
            </li>
            <li>
              Engages in or instigates actions that cause harm to Cloudious or
              other customers. Such actions include, but are not limited to,
              actions resulting in blacklisting any of Our IPs by any online
              spam database, actions resulting in DDOS attacks for any servers,
              etc.;
            </li>
            <li>
              Reverse engineers any API or attempts to use an API to obtain
              confidential information;
            </li>
            <li>
              Circumvents an API in order to violate Cloudious restrictions such
              as, but not limited to, accessing products and services owned by
              other customers, avoiding payment for Services;
            </li>
            <li>Overloads Our systems, including APIs, in any way; or</li>
            <li>
              Violates the Ryan Haight Online Pharmacy Consumer Protection Act
              of 2008 or similar legislation, or promote, encourage or engage in
              the sale or distribution of prescription medication without a
              valid prescription;
            </li>
            <li>
              Violates the privacy or publicity rights of another User or any
              other person or entity, or breaches any duty of confidentiality
              that you owe to another User or any other person or entity.
            </li>
          </ul>
          <p>
            D. You will not access Cloudious Content (defined below) or User
            Content (also defined below) through any technology or means other
            than through this site itself, or as We may designate.
          </p>
          <p>
            E. You agree to back-up all of Your User Content, including email
            and applicable content, so that You can access it when needed.
            Cloudious does not warrant that We back-up any account, User Content
            and/or email and applicable content, and You agree to accept as a
            risk the loss of any and all of Your User Content.
          </p>
          <p>
            F. You agree to provide government-issued photo identification
            and/or government-issued business identification, plus whatever else
            We deem required and necessary, in order to verify Your identity.
          </p>
          <p>
            Cloudious reserves the right to refuse Services to anyone upon Our
            discretion. Any material that in Cloudious’s judgment is either
            obscene or threatening is strictly prohibited and will be removed
            from Cloudious servers immediately with or without prior notice and
            may lead to possible warning, suspension or immediate account
            termination with no refund.
          </p>
          <p>
            You agree that We have the sole right to decide what constitutes a
            violation of the AUP described above as well as what is the
            appropriate severity of any corrective action to be applied. In the
            event that a violation of Our AUP is found, Cloudious will take
            corrective action upon Our own discretion and will notify You.
            Cloudious’s decision in such case is binding and final, and cannot
            be a subject of a further change. Cloudious cannot and shall not be
            liable for any loss or damage arising from Our measures against
            actions causing harm to Cloudious or any other third party.
          </p>
          <p>
            You further agree to the product and service specific AUPs which are
            incorporated herein by reference:
          </p>
          <p>
            <a href="#" target="_blank">Acceptable Use Policy</a>&nbsp;—
            Cloudious Acceptable Use Policy for web hosting clients
          </p>
          <h2 id="n-8-Cloudious-content-user-content">
            8. Cloudious Content; User Content.
          </h2>
          <p>
            In addition to the general rules above, the provisions in this
            section apply specifically to Your use of Cloudious Content and User
            Content posted to Cloudious’s site (i.e. those sites which We
            directly control or maintain). The applicable provisions are not
            intended to and do not have the effect of transferring any ownership
            or licensed rights (including intellectual property rights) You may
            have in content posted to Your websites.
          </p>
          <p>
            <strong>Cloudious Content.</strong>&nbsp;Except for User Content,
            with respect to all content on this site and the Services We offer,
            all right, title and interest in and to all (i) registered and
            unregistered trademarks, service marks and logos; (ii) patents,
            patent applications, and patentable ideas, inventions, and/or
            improvements; (iii) trade secrets, proprietary information, and
            know-how; (iv) all divisions, continuations, reissues, renewals, and
            extensions thereof now existing or hereafter filed, issued, or
            acquired; (v) registered and unregistered copyrights including,
            without limitation, any forms, images, audiovisual displays, text,
            software and (vi) all other intellectual property, proprietary
            rights or other rights related to intangible property which are
            used, developed, comprising, embodied in, or practiced in connection
            with any of the Services identified herein (“IP rights”) are owned
            by Cloudious, and/or where applicable its partners and affiliates,
            and You agree to make no claim of interest in or ownership of any
            such IP rights. You acknowledge that no title to the IP rights is
            transferred to You, and that You do not obtain any rights, express
            or implied, in the Services, other than the rights expressly granted
            in this Agreement.
          </p>
          <p>
            Cloudious Content is provided to you “as is”, “as available” and
            “with all faults” for Your information and personal, non-commercial
            use only and may not be downloaded, copied, reproduced, distributed,
            transmitted, broadcast, displayed, sold, licensed, or otherwise
            exploited for any purposes whatsoever without Our express prior
            written consent. No right or permission under any copyright,
            trademark, patent, or other proprietary right is granted by this
            Agreement. We reserve all rights not expressly granted in and to the
            Cloudious Content, this site, Our Services, and this Agreement do
            not transfer ownership of any of these rights.
          </p>
          <p>
            <strong>User Content.</strong>&nbsp;Some of the features of Our
            site(s) or the Services may allow users to view, post, publish,
            share, store, or manage (a) ideas, opinions, recommendations, or
            advice (“User Submissions”), or (b) literary, artistic, musical, or
            other content, including but not limited to photos and videos, (c)
            reviews, rankings and/or product ratings (“User Reviews”)
            (collectively “User Content”). User Content also includes all
            content submitted through your Account. By posting or publishing
            User Content to this site or to the Services We offer, You represent
            and warrant to Us that (i) You have all necessary rights to
            distribute User Content via this site or via the Services, either
            because You are the author of the User Content and have the right to
            distribute the same, or because You have the appropriate
            distribution rights, licenses, consents, and/or permissions to use,
            in writing, from the copyright or other owner of the User Content,
            and (ii) the User Content does not violate the rights of any third
            party.
          </p>
          <p>
            <strong>Security.</strong>&nbsp;You agree not to circumvent, disable
            or otherwise interfere with the security-related features of this
            site or Our Services (including without limitation those features
            that prevent or restrict use or copying of any Cloudious Content or
            User Content) or enforce limitations on the use of this site or Our
            Services, the Cloudious Content or the User Content therein.
          </p>
          <h2 id="n-9-Cloudiouss-use-of-user-content">
            9. Cloudious’s Use of User Content.
          </h2>
          <p>
            The provisions in this section apply specifically to Cloudious’s use
            of User Content posted to Our websites (i.e., those sites which
            Cloudious directly controls or maintains). The applicable provisions
            are not intended to and do not have the effect of transferring any
            ownership or licensed rights (including intellectual property
            rights) You may have in content posted to Your hosted websites.
          </p>
          <p>
            <strong>In General.</strong>&nbsp;You shall be solely responsible
            for any and all of Your User Content or User Content that is
            submitted through Your Account, and the consequences of, and
            requirements for, distributing it.
          </p>
          <p>
            <strong>User Submissions &amp; User Reviews.</strong>&nbsp;You
            acknowledge and agree that:
          </p>
          <ul class="gb-list">
            <li>
              Your User Submissions and/or User Reviews are entirely voluntary.
            </li>
            <li>
              Your User Submissions and/or User Reviews do not establish a
              confidential relationship or obligate Us to treat Your User
              Submissions as confidential or secret.
            </li>
            <li>
              Cloudious has no obligation, either express or implied, to develop
              or use Your User Submissions or User Reviews, and no compensation
              is due to You or to anyone else for any intentional or
              unintentional use of Your User Submissions or User Reviews.
            </li>
            <li>
              Cloudious may be working on the same or similar content, it may
              already know of such content from other sources, it may simply
              wish to develop this (or similar) content on its own, or it may
              have taken / will take some other action.
            </li>
          </ul>
          <p>
            Cloudious shall own exclusive rights (including all intellectual
            property and other proprietary rights) to any User Submissions
            and/or User Reviews posted to Our site(s), and shall be entitled to
            the unrestricted use and dissemination of any User Submissions or
            User Reviews posted to Our site(s) for any purpose, commercial or
            otherwise, without acknowledgment or compensation to You or to
            anyone else.
          </p>
          <p>
            <strong
              >User Content (Other Than User Submissions/User Reviews).</strong
            >&nbsp;If You have a website or other content hosted by Us, You
            shall retain all of Your ownership or rights in User Content.
          </p>
          <p>
            By posting or publishing User Content to this site or through Our
            Services, You authorize Us to use the intellectual property and
            other proprietary rights in and to Your User Content to enable
            inclusion and use of the User Content in the manner contemplated by
            this Agreement. You hereby grant Cloudious a worldwide,
            non-exclusive, royalty-free, sublicensable (through multiple tiers),
            and transferable license to use, reproduce, distribute, prepare
            derivative works of, combine with other works, display, and perform
            Your User Content in connection with this site, the Services and
            Cloudious’s (and Cloudious’s affiliates’) business(es), including
            without limitation for promoting and redistributing all or part of
            this site in any media formats and through any media channels
            without restrictions of any kind and without payment or other
            consideration of any kind, or permission or notification, to you or
            any third party. You also hereby grant each user of this Site a
            non-exclusive license to access Your User Content (with the
            exception of User Content that you designate “private” or “password
            protected”) through this site, and to use, reproduce, distribute,
            prepare derivative works of, combine with other works, display, and
            perform your User Content as permitted through the functionality of
            this site and under this Agreement. The above licenses granted by
            You in your User Content terminate within a commercially reasonable
            time after You remove or delete Your User Content from this Site.
            You understand and agree, however, that Cloudious may retain (but
            not distribute, display, or perform) server copies of Your User
            Content that have been removed or deleted. The above licenses
            granted by You in Your User Content are perpetual and irrevocable.
            Notwithstanding anything to the contrary contained herein, Cloudious
            shall not use any User Content that has been designated “private” or
            “password protected” by You for the purpose of promoting this site
            or Cloudious’s (or Cloudious’s affiliates’) business(es).
          </p>
          <h2 id="n-10-storage-and-security">10. Storage and Security.</h2>
          <p>
            You are entirely responsible for maintaining the confidentiality of
            Your Account access credentials (including but not limited to Your
            customer username/login, support pin code, password and API key (if
            any)) and Account information. You acknowledge and agree that You
            are solely responsible for all acts, omissions and use under and
            charges incurred with Your account or password or in connection with
            Your content displayed, linked, transmitted through or stored on or
            hosted on Our server. You shall be solely responsible for
            undertaking measures to: (i) prevent any loss or damage to Your
            content; (ii) maintain independent archival and backup copies of
            Your content; (iii) ensure the security, confidentiality and
            integrity of Your content transmitted through or stored on Cloudious
            servers; and (iv) ensure the confidentiality of Your password.
          </p>
          <p>
            Cloudious's servers are not an archive and Cloudious shall have no
            liability to You or any other person for loss, damage or destruction
            of any of Your content. Though some Services offered by Cloudious
            are PCI (Payment Card Industry) compliant, they should not be
            utilized as such without further compliance activity with respect to
            Your business. Cloudious shall have no liability to You or any other
            person for Your use of Cloudious Services in violation of these
            terms. Further, You agree not to undertake any activities that may
            impact or place at risk Cloudious’s ability to maintain Our PCI
            compliance. We reserve the right to take any action necessary to
            ensure Our ongoing PCI compliance status.
          </p>
          <h2
            id="n-11-Cloudious-non-exclusive-right-to-use-links-to-third-party-websites"
          >
            11. Cloudious, Non-Exclusive Right To Use, Links to Third-Party
            Websites.
          </h2>
          <p>
            In using Our Services, You may be granted the ability to use Our
            software and/or third-party software that We make available for Your
            use. You may also choose to add and use third-party software in
            connection with Our Services. Moreover, We may offer third-party
            products and services that require You to access their website in
            order to complete Your purchase and/or agree to additional terms and
            conditions. For these situations, the following provisions apply.
          </p>
          <p>
            <strong>Cloudious Non-Exclusive Right To Use.</strong>&nbsp;If You
            have been given permission or the ability to use software from
            Cloudious, Cloudious grants You a limited, non-exclusive,
            nontransferable and non-assignable right and ability to use the
            software for such purposes as are ordinary and customary. You are
            free to use the software on any computer, but not on two or more
            computers at one time. You agree to not alter or modify the
            software. You agree You are not authorized to combine the software
            with any other software program, create derivative works based upon
            the software, nor are You authorized to integrate any plug-in or
            enhancement which uses or relies upon the software. You further
            agree not to reverse engineer, decompile or otherwise attempt to
            uncover the source code.
          </p>
          <p>
            Cloudious reserves all rights to the software. The software and any
            copies You are authorized to make are the intellectual property of
            Cloudious. The source code and its organization are the exclusive
            property of Cloudious and the software is protected by copyright
            law. Except as expressly provided for in this section, this
            Agreement does not grant You any rights in the software and all
            rights are reserved by Cloudious.
          </p>
          <p>
            Any such software and Services are provided to You "as is" without
            warranty of any kind either express or implied, including but not
            limited to the implied warranties or conditions of merchantability
            or fitness for a particular purpose.
          </p>
          <p>
            <strong>Third-Party Software Use.</strong>&nbsp;Cloudious provides
            some third-party software to You for easier account management. Such
            software is provided on an "as is" as available basis. We do not
            guarantee that any specific results can be obtained by using such
            software. Cloudious does not take responsibility for any faults in
            such software functioning. You agree that Your use of any Cloudious
            Services shall be used by You in accordance with the terms of any
            relevant third-party licenses. Your failure to abide by any
            third-party license may result in the immediate termination of Your
            Services by Cloudious.
          </p>
          <p>
            You can add and use third-party software on Your account only if it
            is compatible with Our servers and is approved by Cloudious. Your
            use of any third party software is at Your own risk. Cloudious does
            not control and therefore cannot be responsible for any third party
            software performance and provides no guarantees that its use will
            result in any particular outcome or result. Cloudious will have no
            liability or responsibility for any damage, loss of data, loss of
            use or other loss occurring in connection with Your use of third
            party software or products. Cloudious reserves the right, at its
            sole discretion, to terminate, suspend, cancel or alter Your access
            to third-party software at any time.
          </p>
          <p>
            You are solely responsible for any license and other fees required
            by the software providers, for using any third-party software
            installed on Your account apart from the initial account setup.
          </p>
          <p>
            <strong>Links to Third-Party Websites Provided By Us.</strong
            >&nbsp;This site and the Services offered by Cloudious, may contain
            links to third-party websites that are not owned or controlled by
            Us. These links include, but are not limited to, links to
            third-party provider services and products through the Cloudious App
            Marketplace. Cloudious assumes no responsibility for the content,
            terms and conditions, privacy policies, or practices of any
            third-party websites. In addition, Cloudious does not censor or edit
            the content of any third-party websites. By using this site or Our
            Services, whether provided directly by Us or by a third-party, You
            expressly release Cloudious from any and all liability arising from
            Your use of any third-party website and/or services offered by them.
            Accordingly, Cloudious encourages You to be aware when You purchase
            or use products/services of third-parties and to review the terms
            and conditions, privacy policies, and other governing documents of
            each other website that You may visit. As between You and Cloudious,
            this Agreement and all of Our policies and additional terms control
            Our relationship with You.
          </p>
          <h2 id="n-12-third-party-content">12. Third-Party Content.</h2>
          <p>
            If You elect to sell or resell advertising or web space to a third
            party then You will be responsible for the contents of that
            advertising and the actions of that third party. Cloudious has the
            absolute right to reject any advertising or other third party
            content that is illegal, offensive, defamatory or otherwise in
            breach of the then current Cloudious policy or agreement. Such
            content may result in the suspension or in the immediate termination
            of Your account.
          </p>
          <h2 id="n-13-privacy">13. Privacy.</h2>
          <p>
            Cloudious's&nbsp;<a href="#" target="_blank">Privacy Policy</a>,
            which is incorporated herein by reference, is applicable to all
            Services. The Privacy Policy sets out Your rights and Cloudious's
            responsibilities with regard to Your personal information. Cloudious
            will not use Your information in any way inconsistent with the
            purposes and limitations provided in the Privacy Policy. You agree
            that Cloudious, in its sole discretion, may modify the Privacy
            Policy, and You further agree that, by using the Services after such
            modifications become effective, You have agreed to these
            modifications. You acknowledge that if You do not agree to any such
            modification, You may terminate this Agreement. Cloudious will not
            refund any fees paid by You if You terminate your Agreement under
            this provision. You represent and warrant that You have provided
            notice to, and obtained consent from, any third party individuals
            whose personal data You supply to Cloudious as part of the Services
            with regard to: (i) the purposes for which such third party’s
            personal data has been collected; (ii) the intended recipients or
            categories of recipients of the third party’s personal data; (iii)
            which parts of the third party’s data are obligatory and which
            parts, if any, are voluntary; and (iv) how the third party can
            access and, if necessary, rectify the data held about them. You
            further agree to provide such notice and obtain such consent with
            regard to any third party personal data You supply to Cloudious in
            the future. Cloudious is not responsible for any consequences
            resulting from Your failure to provide notice or receive consent
            from such individuals nor for Your providing outdated, incomplete or
            inaccurate data.
          </p>
          <h2 id="n-14-trademark-or-copyright-claims">
            14. Trademark or Copyright Claims.
          </h2>
          <p>
            Cloudious is a service provider and respects the copyrights and
            other intellectual property rights of others. To the extent
            Cloudious receives a proper notice of infringement of copyright,
            trademark or other intellectual property, Cloudious reserves the
            right to access, preserve and disclose to third parties any of Your
            information or data (including personally identifiable information
            and private communications) related to a written complaint of
            infringement if Cloudious believes in its sole discretion that such
            access, preservation, or disclosure is necessary or useful to
            respond to or otherwise address such complaint.
          </p>
          <p>
            Cloudious expressly reserves the right to terminate in appropriate
            circumstances an account or the access rights of a subscriber for
            repeated copyright infringement. Cloudious also reserves the right
            to terminate an account or subscriber for even one instance of
            infringement.
          </p>
          <p>
            If You would like to submit (a) a trademark claim for violation of a
            mark on which You hold a valid, registered trademark or service
            mark, or (b) a copyright claim for material on which You hold a bona
            fide copyright, please refer to Cloudious’s&nbsp;<a
              href="#"
              target="_blank"
              >Copyright and Trademark Policies</a
            >.
          </p>
          <h2 id="n-15-no-spam-liquidated-damages">
            15. No Spam; Liquidated Damages.
          </h2>
          <p>
            <strong>No Spam Policy.</strong>&nbsp;We do not tolerate the
            transmission of spam. We monitor all traffic to and from our web
            servers for indications of spamming and maintain a spam abuse
            complaint center to register allegations of spam abuse. Customers
            suspected to be using Our products and services for the purpose of
            sending spam are fully investigated. If We determine there is a
            problem with spam, We will take the appropriate action to resolve
            the situation.
          </p>
          <p>
            We define spam as the sending of Unsolicited Commercial Email (UCE),
            Unsolicited Bulk Email (UBE) or Unsolicited Facsimiles (Fax), which
            is email or facsimile sent to recipients as an advertisement or
            otherwise, without first obtaining prior confirmed consent to
            receive these communications. This can include, but is not limited
            to, the following:
          </p>
          <ol class="gb-list-roman">
            <li>
              Usage of the Cloudious network and systems to receive replies to
              unsolicited mass e-mail messages.
            </li>
            <li>
              Transmission of any unsolicited commercial or bulk email,
              engagement in any activity known or considered to be spamming or
              Mail Bombing.
            </li>
            <li>
              Inappropriate communication to any Newsgroup, Mailing List, Chat
              Facility, or another Internet Forum.
            </li>
            <li>Forgery of e-mail headers (i.e. "spoofing").</li>
            <li>
              Forgery of the signature or other identifying mark or code of any
              other person or engage in any activity to attempt to deceive other
              persons regarding the true identity of the User.
            </li>
            <li>
              Spamming using third-party proxy, aggregation of proxy lists, or
              proxy mailing software installation.
            </li>
            <li>
              Configuring mail servers to accept and process third-party emails
              for sending with no user identification and/or authentication.
            </li>
            <li>
              Engagement in spamvertising or provision of any services that
              support spam.
            </li>
            <li>
              Using weblog posts, IRC/chat room messages, guestbook entries,
              HTTP referrer log entries, use net posts, popups, instant messages
              or text/SMS messages for sending, posting or transmitting
              unsolicited bulk messages.
            </li>
            <li>Advocating any activities prohibited by this Agreement.</li>
            <li>Newsgroup postings</li>
            <li>Windows system messages</li>
            <li>Pop-up messages (aka "adware" or "spyware" messages)</li>
            <li>
              Instant messages (using AOL, MSN, Yahoo or other instant messenger
              programs)
            </li>
            <li>Online chat room advertisements</li>
            <li>Guestbook or Website Forum postings</li>
            <li>Facsimile Solicitations</li>
            <li>Text/SMS Messages</li>
          </ol>
          <p>
            We will not allow Our servers and services to be used for the
            purposes described above. In order to use Our products and services,
            You must not only abide by all applicable laws and regulations,
            which include the Can-Spam Act of 2003 and the Telephone Consumer
            Protection Act, but You must also abide by this no spam policy.
          </p>
          <p>
            If We determine the services in question are being used in
            association with spam, We will re-direct, suspend, or cancel any web
            site hosting, domain registration, email boxes or other applicable
            services for a period deemed appropriate by Cloudious. The
            registrant or customer will be required to respond by email to Us
            stating that they will cease to send spam and/or have spam sent on
            their behalf. We may require a non-refundable reactivation fee to be
            paid before the site, email boxes and/or Services are reactivated.
            In the event We determine the abuse has not stopped after Services
            have been restored the first time, We may terminate any and all
            Services associated with the domain name in question and, if We do,
            no refund will be available to You.
          </p>
          <p>
            We encourage all customers and recipients of email generated from
            our products and services to report suspected spam. Suspected abuse
            can be reported by email or through Our Spam Abuse Complaint Center
            on the Web.&nbsp;<a href="#" target="_blank">Report abuse →</a>
          </p>
          <p>
            <strong>Remedies, Liquidated Damages.</strong>&nbsp;You agree that
            We may immediately terminate any Account which we believe, in Our
            sole and absolute discretion, is transmitting or is otherwise
            connected with any spam or other unsolicited bulk email. In
            addition, if actual damages cannot be reasonably calculated then You
            agree to pay Us liquidated damages in the amount of $500 or $1.00
            for each piece of spam or unsolicited bulk email transmitted from or
            otherwise connected with Your Account, whichever amount is greater.
          </p>
          <h2 id="n-16-use-of-free-email-forwarding-services">
            16. Use of Free Email Forwarding Services.
          </h2>
          <p>
            In order to safeguard overall server performance, You may forward
            not more than 500 emails/hour per domain.
          </p>
          <p>
            Cloudious may, at its sole discretion, limit the volume of email
            messages You can deliver through our services. Cloudious may limit
            email volume by queuing Your email messages internally, or by
            temporarily rejecting requests to send email through our services.
            Cloudious may block any message You attempt to submit using our
            services, for any reason whatsoever, with or without notifying You
            of such blocking. Under no circumstances will Cloudious be liable to
            You or any other party for any indirect, special, economic or
            consequential damages (including without limitation lost profits)
            arising out of email blocking or queuing.
          </p>
          <h2 id="n-17-additional-reservation-of-rights">
            17. Additional Reservation of Rights.
          </h2>
          <p>
            Cloudious expressly reserves the right to deny, cancel, terminate,
            suspend, lock, or modify access to (or control of) any account or
            any Services (including the right to cancel or transfer any domain
            name registration) for any reason (as determined by Cloudious in its
            sole and absolute discretion), including but not limited to the
            following: (i) to correct mistakes made by Cloudious in offering or
            delivering any Services (including any domain name registration);
            (ii) to protect the integrity and stability of, and correct mistakes
            made by, any domain name registry; (iii) to assist with our fraud
            and abuse detection and prevention efforts; (iv) to comply with
            applicable local, state, national and international laws, rules and
            regulations; (v) to comply with requests of law enforcement,
            including subpoena requests; (vi) to comply with any dispute
            resolution process; (vii) to defend any legal action or threatened
            legal action without consideration for whether such legal action or
            threatened legal action is eventually determined to be with or
            without merit, or (viii) to avoid any civil or criminal liability on
            the part of Cloudious, its officers, directors, employees and
            agents, as well as Cloudious’s affiliates.
          </p>
          <p>
            In the event that Cloudious need exercise any of its rights
            expressed herein to investigate any potential breach or violation of
            the terms and conditions of this Agreement, service fees may
            continue to accrue on Your accounts, and You will continue to remain
            responsible for the payment of any service fees that accrue during
            the relevant period.
          </p>
          <h2>
            18. Billing &amp; Payment; Currency; Termination &amp; Cancellation
            Policy.
          </h2>
          <p>
            <strong>Billing and Payment.</strong>&nbsp;All fees for the Services
            shall be in accordance with Cloudious's fee schedule then in effect,
            the terms of which are incorporated herein by reference, and shall
            be due at the time You order the Services, unless otherwise noted.
            You may pay for Services by providing a valid credit or debit card,
            an electronic check (from your personal or business checking
            account, as appropriate), PayPal, Bitcoin, or any other payment
            method then accepted by Cloudious (each a “Payment Method”);
            provided, however, that We may at Our option require that You pay
            fees through a particular payment means (such as by credit card or
            by wire transfer) or that You change from one payment provider to
            another. Charges for the Service(s) will be billed to Your chosen
            Payment Method as charges for “NAME-CHEAP.COM.” The statement
            descriptor may also include a unique identifier of the transaction.
            Cloudious is not responsible for any changes in statement
            descriptors made by Your credit card issuer. If You choose to pay
            for the Service(s) by credit card, Our payment processing service
            provider may, at the beginning of the payment process, pre-authorize
            the transaction charges against Your credit card. This process
            confirms both the validity of the credit card and the availability
            of sufficient funds to finalize the transaction. If, after
            commencing the payment process, You subsequently elect not to
            finalize Your transaction, We will clear any pre-authorized charges
            from Our systems and reverse the payment within two [2] hours.
            Depending on Your credit card provider and their policies,
            pre-authorized charges may continue to be reflected in Your credit
            card account details for longer than this two [2] hour period. All
            prices and fees are non-refundable unless otherwise expressly noted,
            even if Your Services are suspended, terminated, or transferred
            prior to the end of the Services term. Cloudious expressly reserves
            the right to change or modify its prices and fees at any time, and
            such changes or modifications shall be posted online at this site,
            or the relevant site of the Service, and effective immediately
            without need for further notice to You. If You have purchased or
            obtained Services for a period of months or years, changes or
            modifications in prices and fees shall be effective when the
            Services in question come up for renewal as further described below.
          </p>
          <p>
            IN ORDER TO ENSURE THAT YOU DO NOT EXPERIENCE AN INTERRUPTION OR
            LOSS OF SERVICES, MOST SERVICES OFFER AN AUTOMATIC RENEWAL OPTION.
            THE AUTOMATIC RENEWAL OPTION WILL ATTEMPT TO AUTOMATICALLY RENEW THE
            APPLICABLE SERVICE FOR A RENEWAL PERIOD EQUAL IN TIME TO THE MOST
            RECENT SERVICE PERIOD, EXCEPT FOR DOMAIN NAMES WHICH WILL RENEW FOR
            THE ORIGINAL SERVICE PERIOD. FOR EXAMPLE, FOR PRODUCTS OTHER THAN
            DOMAINS, IF YOUR LAST SERVICE PERIOD IS FOR ONE YEAR, YOUR RENEWAL
            PERIOD WILL BE FOR ONE YEAR. WITH THE AUTOMATIC RENEWAL OPTION,
            Cloudious WILL ATTEMPT TO RENEW THE APPLICABLE SERVICE WHEN IT COMES
            UP FOR RENEWAL AND WILL TAKE PAYMENT FROM THE PAYMENT METHOD YOU
            HAVE ON FILE WITH US AT Cloudious’S THEN CURRENT RATES, WHICH YOU
            ACKNOWLEDGE AND AGREE MAY BE HIGHER OR LOWER THAN THE RATES FOR THE
            ORIGINAL SERVICE PERIOD. PLEASE NOTE THAT RENEWAL DATES VARY BY
            SERVICE. FOR INSTANCE, SOME SERVICES MAY RENEW THIRTY (30) DAYS
            PRIOR TO EXPIRATION AND OTHERS MAY RENEW FIVE (5) DAYS PRIOR TO
            EXPIRATION. YOU MAY ENABLE OR DISABLE THE AUTOMATIC RENEWAL OPTION
            AT ANY TIME. HOWEVER, SHOULD YOU ELECT TO DISABLE THE AUTOMATIC
            RENEWAL OPTION AND FAIL TO MANUALLY RENEW YOUR SERVICES BEFORE THEY
            EXPIRE OR IF YOUR PAYMENT METHOD CHOSEN FOR YOUR AUTOMATIC RENEWAL
            SHOULD FAIL, YOU MAY EXPERIENCE AN INTERRUPTION OR LOSS OF SERVICES,
            AND Cloudious SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY
            REGARDING THE SAME. Cloudious RESERVES THE RIGHT TO DETERMINE
            WHETHER A SECOND ATTEMPT AT PAYMENT WILL BE MADE SHOULD THE CHOSEN
            PAYMENT METHOD FAIL.
          </p>
          <p>
            IN ADDITION, Cloudious MAY PARTICIPATE IN “RECURRING BILLING
            PROGRAMS” OR “ACCOUNT UPDATER SERVICES” SUPPORTED BY YOUR
            THIRD-PARTY BILLING PROVIDER. IF YOU ARE ENROLLED IN AN AUTOMATIC
            RENEWAL OPTION AND WE ARE UNABLE TO SUCCESSFULLY CHARGE YOUR
            EXISTING PAYMENT METHOD, YOUR CREDIT CARD PROVIDER (OR YOUR BANK)
            MAY NOTIFY US OF UPDATES TO YOUR CREDIT CARD NUMBER AND/OR
            EXPIRATION DATE, OR THEY MAY AUTOMATICALLY CHARGE YOUR NEW CREDIT
            CARD ON OUR BEHALF WITHOUT NOTIFICATION TO US. IN ACCORDANCE WITH
            RECURRING BILLING PROGRAM REQUIREMENTS, IN THE EVENT THAT WE ARE
            NOTIFIED OF AN UPDATE TO YOUR CREDIT CARD NUMBER AND/OR EXPIRATION
            DATE, Cloudious WILL AUTOMATICALLY UPDATE YOUR PAYMENT PROFILE ON
            YOUR BEHALF. Cloudious MAKES NO GUARANTEES THAT WE WILL REQUEST OR
            RECEIVE UPDATED CREDIT CARD INFORMATION. YOU ACKNOWLEDGE AND AGREE
            THAT IT IS YOUR SOLE RESPONSIBILITY TO MODIFY AND MAINTAIN YOUR
            ACCOUNT SETTINGS, INCLUDING BUT NOT LIMITED TO (I) SETTING YOUR
            RENEWAL OPTIONS AND (II) ENSURING YOUR ASSOCIATED PAYMENT METHOD(S)
            ARE CURRENT AND VALID. FURTHER, YOU ACKNOWLEDGE AND AGREE THAT YOUR
            FAILURE TO DO SO MAY RESULT IN THE INTERRUPTION OR LOSS OF SERVICES,
            AND Cloudious SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY
            REGARDING THE SAME.
          </p>
          <p>
            If for any reason Cloudious is unable to charge your payment method
            for the full amount owed for the Services provided, or if We receive
            notification of a chargeback, reversal, payment dispute, or are
            charged a penalty for any fee previously charged to your payment
            method, You agree that Cloudious may pursue all available lawful
            remedies in order to obtain payment, including but not limited to,
            immediate cancellation, without notice to You, of any domain names
            or Services registered or renewed on Your behalf. You also agree
            that all rights to and interest in and use of any Services, content
            and/or products purchased through Us, including all data hosted on
            Our systems shall be assumed by Us in satisfaction of any
            indebtedness by You to Us. Our reinstatement fee is currently equal
            to the fee charged by the payment processor involved in the
            transaction reversal.
          </p>
          <p>
            Cloudious also reserves the right to charge You reasonable
            "administrative fees" or "processing fees" for (i) tasks Cloudious
            may perform outside the normal scope of its Services, (ii)
            additional time and/or costs We may incur in providing its Services,
            and/or (iii) Your noncompliance with this Agreement (as determined
            by Cloudious in its sole and absolute discretion). Typical
            administrative or processing fee scenarios include, but are not
            limited to (i) customer service issues that require additional
            personal time or attention; (ii) investigation into the use of
            Cloudious services where it is alleged and verified by Cloudious
            that your use of such services were for abuse; (iii) account
            maintenance where there has been inactivity in your account for 6
            months or more; (iv) UDRP action(s) in connection with Your domain
            name(s) and/or disputes that require accounting or legal services,
            whether performed by Cloudious staff or by outside firms retained by
            Cloudious; (v) recouping any and all costs and fees, including the
            cost of Services, incurred by Cloudious as the results of
            chargebacks or other payment disputes brought by You, Your bank or
            payment method processor. These administrative fees or processing
            fees will be billed to the payment method You have on file with
            Cloudious.
          </p>
          <p>
            If You signed up for a monthly payment plan, Your monthly billing
            date will be determined based on the day of the month You purchased
            the products or Services. If You signed up for an annual (or longer)
            payment plan, and You elected the automatic renewal option,
            Cloudious will automatically renew Your Services when they come up
            for renewal and will take payment in accordance with the designated
            payment method at Cloudious's then current rates.
          </p>
          <p>
            <strong>Currency.</strong>&nbsp;While all purchases are processed in
            US dollars, Cloudious may provide an estimated conversion price to
            currencies other than US dollars. You acknowledge and agree that the
            pricing displayed during the checkout process is an estimate. Due to
            potential slight time delays between actual purchase and the payment
            settlement, the actual price charged may fluctuate. Accordingly,
            Cloudious makes no representations or warranties that the actual
            price will be the same or substantially similar to the actual price
            You will pay and You waive any and all claims based upon any
            discrepancy between the estimate and the actual price. In addition,
            You may be charged VAT or additional off-shore margin and/or fees,
            based on the country indicated in Your billing address section. Any
            amounts to be charged will appear during the checkout process.
          </p>
          <p>
            <strong>Termination &amp; Cancellation Policy.</strong>&nbsp;The
            initial term of Your agreement with Cloudious shall be as set forth
            in Your Order Form. The Initial Term shall begin upon commencement
            of the Services in the Order Form. After the Initial Term, your
            agreement with Cloudious shall automatically renew for successive
            terms of equal length as the Initial Term, unless terminated or
            cancelled by either party as provided in this section.
          </p>
          <p>
            This agreement may be terminated: (i) by You when You discontinue
            the use of Our Services or (ii) by Cloudious at any time, without
            prior notice, if, in Cloudious's judgment, You are in violation of
            any terms or conditions herein; or (iii) in Cloudious's sole
            judgment, Your use of the Services places or is likely to place
            unreasonable demands upon Cloudious or could disrupt Cloudious's
            business operations; or (iv) by Cloudious if it so determines that
            You are or are alleged to be violating the terms and conditions of
            any other agreement entered into by You and Cloudious.
          </p>
          <p>
            In the event of termination or suspension of Services under the
            above circumstances, You agree (a) that no pre-paid fees will be
            refunded to You; and (b) that Cloudious may take control of any
            domain name associated with the terminated Services, provided such
            domain name was registered through the domain name registration of
            Cloudious.
          </p>
          <p>Refunds do NOT apply to Services unless otherwise specified.</p>
          <p>
            In the event of termination of this Agreement caused by Your default
            hereunder, You shall bear all costs of termination, including any
            reasonable costs Cloudious incurs in closing Your account. You agree
            to pay any and all costs incurred by Cloudious in enforcing Your
            compliance with this section. Upon termination, You shall destroy
            any copy of the materials provided to You hereunder and referenced
            herein. You agree that upon termination or discontinuance for any
            reason, Cloudious may delete all information related to You on the
            Services.
          </p>
          <h2>19. Customer Support.</h2>
          <p>
            Cloudious provides customer support to You at no additional fee for
            issues related to Cloudious Services only. Cloudious has the right
            to decide what is a service related issue and to charge additional
            fees or refuse support for non-service related issues. Any fees paid
            by You for providing non-service related support are non-refundable.
            Cloudious will also require, before assistance can be given, that
            You verify Your identity in relation to the Account in question. We
            will determine, in Our sole and absolute discretion, what must be
            provided for verification purposes, including the use of secure
            validation tools such as&nbsp;Veriff.
          </p>
          <p>
            Unless otherwise directed by a specific Service, You can request
            customer support only by opening Live Chat or Ticket through the
            HelpDesk system located in the Customer area. Cloudious will have no
            liability to provide customer support if it is requested in any
            other way apart from the HelpDesk system or the instructions
            specific to the Service at issue. You are solely responsible to use
            the appropriate HelpDesk category when opening Live Chat or posting
            Ticket. Cloudious will have no liability to respond to tickets
            opened in inappropriate categories. Cloudious shall not be liable
            for any delay in Live Chat and/or Ticket opened in inappropriate
            categories. You acknowledge that by asking our customer support
            representatives for assistance, You authorize their intervention and
            operation in Your account.
          </p>
          <p>
            You must provide Cloudious with all information and access to
            facilities that Cloudious may reasonably require to provide the
            requested customer support. You are solely liable for performing and
            storing a back-up copy of data, files, hosting account and any other
            content prior to requesting customer support and agreeing to any
            interference or operation, provided by Cloudious. In the event You
            are not satisfied with the outcome of any action You shall be solely
            responsible for restoring the back-up copies of Your data. You
            should not abuse the HelpDesk system. Abuse of the HelpDesk system
            includes, but is not limited to, excessive number of Live Chats and
            Tickets opened by a single Customer, aggressive and/or harassing
            behavior, repetitive use of inappropriate categories for opening
            Live Chats, posting Tickets, etc. Any abuse of the HelpDesk system
            may result in warning, HelpDesk access restrictions, account
            suspension or possible account termination with no refund. Cloudious
            has the sole right to decide what constitutes abuse of the HelpDesk
            system.
          </p>
          <h2>20. Account Use.</h2>
          <p>
            You are responsible for security of Your Account access credentials.
            Cloudious will not change passwords to any account. Should You need
            to restore access to Your account, You will need to provide Us with
            appropriate identification, as determined by Us in Our sole
            discretion, and We will initiate a password reset process for You to
            complete. In the event of any partnership break-up, divorce or other
            legal problems that includes You, You understand that Cloudious will
            remain neutral and may put the account on hold until the situation
            has been resolved. Under no circumstances will Cloudious be liable
            for any losses incurred by You during this time of determination of
            ownership, or otherwise. You agree to defend (through counsel of Our
            choosing), indemnify and hold harmless Cloudious from any and all
            claims arising from such ownership disputes. If you are required to
            supply or transmit sensitive information to Cloudious you should
            take all due precautions to provide any sensitive information over a
            secure communication channel.
          </p>
          <h2>21. Disclaimer of Representations and Warranties.</h2>
          <p>
            YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT YOUR USE OF THIS SITE
            AND OUR SERVICES SHALL BE AT YOUR OWN RISK AND THAT SERVICES FOUND
            ARE PROVIDED “AS IS”, “AS AVAILABLE” AND “WITH ALL FAULTS”.
            Cloudious, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, AND ALL THIRD
            PARTY SERVICE PROVIDERS DISCLAIM ALL WARRANTIES, STATUTORY, EXPRESS
            OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF
            TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
            NON-INFRINGEMENT. Cloudious, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND
            AGENTS MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT (I) THE ACCURACY,
            COMPLETENESS, OR CONTENT OF THIS SITE, (II) THE ACCURACY,
            COMPLETENESS, OR CONTENT OF ANY SITES LINKED (THROUGH HYPERLINKS,
            BANNER ADVERTISING OR OTHERWISE) TO THIS SITE, AND/OR (III) THE
            SERVICES FOUND AT THIS SITE OR ANY SITES LINKED (THROUGH HYPERLINKS,
            BANNER ADVERTISING OR OTHERWISE) TO THIS SITE, AND Cloudious ASSUMES
            NO LIABILITY OR RESPONSIBILITY FOR THE SAME. YOU UNDERSTAND AND
            AGREE THAT ANY MATERIAL AND/OR DATA DOWNLOADED OR OTHERWISE OBTAINED
            THROUGH THE USE OF OUR SERVICES IS DONE AT YOUR OWN DISCRETION AND
            RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
            COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF
            SUCH MATERIAL AND/OR DATA.
          </p>
          <p>
            IN ADDITION, YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT NO ORAL OR
            WRITTEN INFORMATION OR ADVICE PROVIDED BY Cloudious, ITS OFFICERS,
            DIRECTORS, EMPLOYEES, OR AGENTS (INCLUDING WITHOUT LIMITATION ITS
            SUPPORT CENTER OR CUSTOMER SERVICE REPRESENTATIVES), AND THIRD PARTY
            SERVICE PROVIDERS WILL (I) CONSTITUTE LEGAL OR FINANCIAL ADVICE OR
            (II) CREATE A WARRANTY OF ANY KIND WITH RESPECT TO THIS SITE OR THE
            SERVICES FOUND AT THIS SITE, AND USERS SHOULD NOT RELY ON ANY SUCH
            INFORMATION OR ADVICE.
          </p>
          <p>
            THE FOREGOING DISCLAIMER OF REPRESENTATIONS AND WARRANTIES SHALL
            APPLY TO THE FULLEST EXTENT PERMITTED BY LAW, AND SHALL SURVIVE ANY
            TERMINATION OR EXPIRATION OF THIS AGREEMENT OR YOUR USE OF THIS SITE
            OR OUR SERVICES.
          </p>
          <h2>22. Limitation of Liability; Waiver and Release.</h2>
          <p>
            IN NO EVENT SHALL Cloudious, ITS OFFICERS, DIRECTORS, EMPLOYEES,
            AGENTS, AND ALL THIRD PARTY SERVICE PROVIDERS, BE LIABLE TO YOU OR
            ANY OTHER PERSON OR ENTITY FOR ANY DIRECT, INDIRECT, INCIDENTAL,
            SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING
            ANY THAT MAY RESULT FROM (I) THE ACCURACY, COMPLETENESS, OR CONTENT
            OF THIS SITE, (II) THE ACCURACY, COMPLETENESS, OR CONTENT OF ANY
            SITES LINKED (THROUGH HYPERLINKS, BANNER ADVERTISING OR OTHERWISE)
            TO THIS SITE, (III) OUR SERVICES WHETHER FOUND AT THIS SITE OR ANY
            SITES LINKED (THROUGH HYPERLINKS, BANNER ADVERTISING OR OTHERWISE)
            TO THIS SITE, (IV) PERSONAL INJURY OR PROPERTY DAMAGE OF ANY NATURE
            WHATSOEVER, (V) THIRD-PARTY CONDUCT OF ANY NATURE WHATSOEVER, (VI)
            ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS AND/OR ANY AND ALL
            CONTENT, PERSONAL INFORMATION, FINANCIAL INFORMATION OR OTHER
            INFORMATION AND DATA STORED THEREIN, (VII) ANY INTERRUPTION OR
            CESSATION OF SERVICES TO OR FROM THIS SITE OR ANY SITES LINKED
            (THROUGH HYPERLINKS, BANNER ADVERTISING OR OTHERWISE) TO THIS SITE,
            (VIII) ANY VIRUSES, WORMS, BUGS, TROJAN HORSES, OR THE LIKE, WHICH
            MAY BE TRANSMITTED TO OR FROM THIS SITE OR ANY SITES LINKED (THROUGH
            HYPERLINKS, BANNER ADVERTISING OR OTHERWISE) TO THIS SITE, (IX) ANY
            USER CONTENT OR CONTENT THAT IS DEFAMATORY, HARASSING, ABUSIVE,
            HARMFUL TO MINORS OR ANY PROTECTED CLASS, PORNOGRAPHIC, “X-RATED”,
            OBSCENE OR OTHERWISE OBJECTIONABLE, AND/OR (X) ANY LOSS OR DAMAGE OF
            ANY KIND INCURRED AS A RESULT OF YOUR USE OF THIS SITE OR OUR
            SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER
            LEGAL OR EQUITABLE THEORY, AND WHETHER OR NOT Cloudious IS ADVISED
            OF THE POSSIBILITY OF SUCH DAMAGES.
          </p>
          <p>
            YOU AGREE THAT Cloudious WILL NOT BE LIABLE FOR ANY (I) SUSPENSION
            OR LOSS OF THE SERVICES; (II) INTERRUPTION OF BUSINESS; (III) ACCESS
            DELAYS OR ACCESS INTERRUPTIONS TO THE WEBSITE(S) PROVIDED THROUGH OR
            BY THE SERVICES; (IV) LOSS OR LIABILITY RESULTING FROM ACTS OF GOD;
            (V) DATA NON-DELIVERY, MIS-DELIVERY, CORRUPTION, DESTRUCTION OR
            OTHER MODIFICATION; (IV) EVENTS BEYOND THE CONTROL OF Cloudious;
            (VII) THE PROCESSING OF YOUR APPLICATION FOR SERVICES; OR (VIII)
            LOSS OR LIABILITY RESULTING FROM THE UNAUTHORIZED USE OR MISUSE OF
            YOUR ACCOUNT IDENTIFIER OR PASSWORD.
          </p>
          <p>
            IN ADDITION, YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT ANY CAUSE
            OF ACTION ARISING OUT OF OR RELATED TO THIS SITE OR OUR SERVICES
            MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION
            ACCRUES, OTHERWISE SUCH CAUSE OF ACTION SHALL BE PERMANENTLY BARRED.
          </p>
          <p>
            IN ADDITION, YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT IN NO EVENT
            SHALL Cloudious’S TOTAL AGGREGATE LIABILITY EXCEED THE TOTAL AMOUNT
            PAID BY YOU FOR THE PARTICULAR SERVICES THAT ARE THE SUBJECT OF THE
            CAUSE OF ACTION.
          </p>
          <p>
            THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST
            EXTENT PERMITTED BY LAW, AND SHALL SURVIVE ANY TERMINATION OR
            EXPIRATION OF THIS AGREEMENT OR YOUR USE OF THIS SITE OR THE
            SERVICES FOUND AT THIS SITE.
          </p>
          <p>
            NO WAIVER OF ANY PROVISION OF THIS AGREEMENT SHALL BE EFFECTIVE
            UNLESS IT IS IN WRITING AND SIGNED BY AN AUTHORIZED REPRESENTATIVE
            OF Cloudious.
          </p>
          <h2>23. Indemnification.</h2>
          <p>
            Accordingly, You for Yourself and all of Your heirs, personal
            representatives, predecessors, successors and assigns, hereby fully
            release, remise, and forever discharge Cloudious and all affiliates
            of Cloudious, and all officers, agents, employees, and
            representatives of Cloudious, and all of their heirs, personal
            representatives, predecessors, successors and assigns, for, from and
            against any and all claims, liens, demands, causes of action,
            controversies, offsets, obligations, losses, damages and liabilities
            of every kind and character whatsoever, including, but not limited
            to, any action omission, misrepresentation or other basis of
            liability founded either in tort or contract and the duties arising
            thereunder, whether known or unknown, relating to or arising out of,
            or in any way connected with or resulting from, the Services and
            Your acquisition and use thereof, including, but not limited to, the
            provision of Cloudious products and/or services by Cloudious and its
            agents and employees. Further, You agree to defend, indemnify and
            hold harmless Cloudious and any of its contractors, agents,
            employees, officers, directors, shareholders, affiliates and assigns
            from any loss, liability, damages or expense, including reasonable
            attorneys' fees, arising out of (i) any breach of any representation
            or warranty provided in this Agreement, or as provided by
            Cloudious’s AUP or any other agreement that has been incorporated by
            reference herein; (ii) the Services or Your use of the Services,
            including without limitation infringement or dilution by You or by
            another using the Services from Your computer; (iii) any
            intellectual property or other proprietary right of any person or
            entity; (iv) any information or data You supplied to Cloudious,
            including, without limitation, any misrepresentation in Your
            application, if applicable; (v) the inclusion of metatags or other
            elements in any website created for You or by You via the Services;
            (vi) any information, material, or services available on Your
            Cloudious hosted website; or (vii), any negligence or willful
            misconduct by You, or any allegation that Your account infringes a
            third person's copyright, trademark or proprietary or intellectual
            property right, or misappropriates a third person's trade secrets.
          </p>
          <p>
            This indemnification is in addition to any indemnification required
            of You elsewhere. Should Cloudious be notified of a pending lawsuit,
            or receive notice of the filing of a lawsuit, Cloudious may seek a
            written confirmation from You concerning Your obligation to defend,
            indemnify and hold harmless Cloudious. Such written confirmation may
            include the posting of performance bonds or other guarantees. Your
            failure to provide such a confirmation may be considered a breach of
            this agreement. You agree that Cloudious shall have the right to
            participate in the defense of any such claim through counsel of its
            Own choosing. You agree to notify Cloudious of any such claim
            promptly in writing and to allow Cloudious to control the
            proceedings. You agree to cooperate fully with Cloudious during such
            proceedings. The terms of this section will survive any termination
            or cancellation of this Agreement.
          </p>
          <h2>24. Governing Law; Jurisdiction; Waiver of Trial By Jury.</h2>
          <p>
            Except as otherwise set forth in the UDRP or any similar policy with
            respect to any dispute regarding the Services provided under this
            Agreement, Your rights and obligations and all actions contemplated
            by this Agreement shall be governed by the laws of the United States
            of America and the State of Arizona. You agree that any action to
            enforce this agreement or any matter relating to Your use of the
            Services must be brought exclusively in the United States District
            Court of Arizona, or if there is no jurisdiction in such court, then
            in a state court in Maricopa County, State of Arizona. You agree to
            waive the right to a trial by jury in any action or proceeding that
            takes place relating to or arising out of this Agreement.
          </p>
          <h2>25. No Third Party Beneficiary Rights.</h2>
          <p>
            This Agreement, and any/all supplements to it, is not intended to
            and shall not be construed to give any Third Party any interest or
            rights (including, without limitation, any third party beneficiary
            rights) with respect to or in connection with any agreement or
            provision contained herein or contemplated hereby.
          </p>
          <h2 id="n-28-notices">26. Notices.</h2>
          <p>
            You agree that any notices required to be given under this Agreement
            by Us to You will be deemed to have been given if delivered in
            accordance with the account and/or, if domain related, the domain
            name Whois information You have provided. You acknowledge that it is
            Your responsibility to maintain current contact information in the
            account and/or domain name Whois information You have provided.
          </p>
          <h2 id="n-29-final-agreement">27. Final Agreement.</h2>
          <p>
            This Agreement, together with all modifications, constitutes the
            complete and exclusive agreement between You and Us, and supersedes
            and governs all prior proposals, agreements, or other
            communications. This Agreement may not be amended or modified by You
            except by means of a written document signed by both You and an
            authorized representative of Us. By applying for Cloudious’s
            Services through the online application process or otherwise, or by
            using the Services under this Agreement, you acknowledge that You
            have read and agree to be bound by all terms and conditions of this
            Agreement and documents incorporated by reference.
          </p>
          <h2 id="n-30-no-agency-relationship">28. No Agency Relationship.</h2>
          <p>
            Nothing contained in this Agreement shall be construed as creating
            any agency, partnership, or other form of joint enterprise between
            the parties hereto. Each party shall ensure that the foregoing
            persons shall not represent to the contrary, either expressly,
            implicitly, by appearance or otherwise.
          </p>
          <h2 id="n-31-enforceability">29. Enforceability.</h2>
          <p>
            In the event that any provision of this Agreement shall be
            unenforceable or invalid under any applicable law or be so held by
            applicable court decision, such unenforceability or invalidity shall
            not render this Agreement unenforceable or invalid as a whole. We
            will amend or replace such provision with one that is valid and
            enforceable and which achieves, to the extent possible, our original
            objectives and intent as reflected in the original provision.
          </p>
          <h2 id="n-32-assignment-and-resale">30. Assignment and Resale.</h2>
          <p>
            Except as otherwise set forth herein, Your rights under this
            Agreement are not assignable or transferable. Any attempt by Your
            creditors to obtain an interest in Your rights under this Agreement,
            whether by attachment, levy, garnishment or otherwise, renders this
            Agreement voidable at Our option. You agree not to reproduce,
            duplicate, copy, sell, resell or otherwise exploit for any
            commercial purposes any of the Services (or portion thereof) without
            Cloudious's prior express written consent.
          </p>
          <h2 id="n-33-force-majeure">31. Force Majeure.</h2>
          <p>
            Neither party shall be deemed in default hereunder, nor shall it
            hold the other party responsible for, any cessation, interruption or
            delay in the performance of its obligations hereunder due to causes
            beyond its control including, but not limited to: earthquake; flood;
            fire; storm; natural disaster; act of God; war; terrorism; armed
            conflict; labor strike; lockout; boycott; supplier failures,
            shortages, breaches, or delays; or any law, order regulation,
            direction, action or request of the government, including any
            federal, state and local governments having or claiming jurisdiction
            over Cloudious, or of any department, agency, commission, bureau,
            corporation or other instrumentality of any federal, state, or local
            government, or of any civil or military authority; or any other
            cause or circumstance, whether of a similar or dissimilar nature to
            the foregoing, beyond the reasonable control of the affected party,
            provided that the party relying upon this section (i) shall have
            given the other party written notice thereof promptly and, in any
            event, within five (5) days of discovery thereof and (ii) shall take
            all steps reasonably necessary under the circumstances to mitigate
            the effects of the force majeure event upon which such notice is
            based; provided further, that in the event a force majeure event
            described in this section extends for a period in excess of thirty
            (30) days in the aggregate, Cloudious may immediately terminate this
            Agreement.
          </p>
          <h2 id="n-34-headings">32. Headings.</h2>
          <p>
            The section headings appearing in this Agreement are inserted only
            as a matter of convenience and in no way define, limit, construe or
            describe the scope or extent of such section or in any way affect
            such section.
          </p>
          <h2 id="n-35-contact-information">33. Contact Information.</h2>
          <p>
            If you have any questions about this Privacy Policy, You can contact
            us:
          </p>
          <ul>
            <li>
              By email:
              <a href="mailto: support@cloudious.net">support@cloudious.net</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
